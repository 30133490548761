import { render, staticRenderFns } from "./risico-rubriek.vue?vue&type=template&id=c9ea2fee"
import script from "./risico-rubriek.ts?vue&type=script&lang=ts&external"
export * from "./risico-rubriek.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports