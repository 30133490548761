import { computed, defineComponent, PropType, ref, watch } from 'vue'
import {
  Beroepsprocedure,
} from '@/infrastructure/bff-client/bff-client'
import useBeroepLabels
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/shared/beroepsprocedure/composable/beroep-labels.composable'

export default defineComponent({
  name: 'beroepsprocedure',
  props: {
    inlichtingKey: String,
    validationErrors: Map<string, string>,
    saving: Boolean,
    procedure: Object as PropType<Beroepsprocedure>,
    procedureIndex: Number,
    defaultInstantie: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update'],
  setup (props, { emit }) {
    const input = ref(props.procedure as any)
    const { beroepsinstantieOptions, beslissingOptions } = useBeroepLabels()

    const optionsBeslissing = computed(() => beslissingOptions(input.value.beroepsinstantie))

    watch(input, (newValue) => {
      emit('update', { procedure: newValue, index: props.procedureIndex })
    }, { deep: true, immediate: false })

    return {
      input,
      beroepsinstantieOptions,
      optionsBeslissing
    }
  }
})
