import { defineComponent, PropType, ref, watch } from 'vue'
import { formatDate, formatNumber } from '@/infrastructure/filters/filters'
import {
  Document,
  OmgevingsvergunningInput,
  BeslissingOverDeMelding,
} from '@/infrastructure/bff-client/bff-client'
import useOmgevingVergunningLabels from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/omgevingsvergunning/composables/omgevingsvergunning-labels'
import BeslissingenEdit from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/omgevingsvergunning/edit/beslissingen/beslissingen-edit.vue'
import ProjectElementenEdit
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/omgevingsvergunning/edit/project-elementen/project-elementen-edit.vue'

export default defineComponent({
  name: 'omgevingsvergunning-edit',
  components: {
    ProjectElementenEdit,
    BeslissingenEdit,
  },
  props: {
    input: Object as PropType<OmgevingsvergunningInput>,
    bijlagen: Array as PropType<Document[]>,
    inlichtingId: String,
    dossierId: String,
    inlichtingKey: String,
    validationErrors: Map<string, string>,
    saving: Boolean,
  },
  setup (props, { emit }) {
    const { options, getLabel } = useOmgevingVergunningLabels()

    const inlichtingInput = ref(props.input)
    const beslisingen = ref(props.input.beslissingen ?? ({} as BeslissingOverDeMelding))
    const bijlageUploaded = (bijlagen: File[]) => {
      emit('bijlage-uploaded', bijlagen)
    }

    const proceduresUpdated = (input: OmgevingsvergunningInput) => {
      inlichtingInput.value = input
    }

    watch(inlichtingInput, (newValue) => emit('input-updated', newValue), { deep: true })

    return {
      inlichtingInput,
      beslisingen,
      formatNumber,
      getLabel,
      formatDate,
      bijlageUploaded,
      proceduresUpdated,
      options,
    }
  },
})
