import {
  ExploitatieVanIngedeeldeInrichtingenOfActiviteitenActiviteit,
  ExploitatieVanIngedeeldeInrichtingenOfActiviteitenActiviteitRisicoklasse,
  ExploitatieVanIngedeeldeInrichtingenOfActiviteitenActiviteitStatus,
  ExploitatieVanIngedeeldeInrichtingenOfActiviteitenActiviteitVlareboCode
} from '@/infrastructure/bff-client/bff-client'
import { defineComponent, PropType, ref, watch } from 'vue'
import useOmgevingVergunningLabels from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/omgevingsvergunning/composables/omgevingsvergunning-labels'
import { formatDate } from '@/infrastructure/filters/filters'
import useEditHelper from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/omgevingsvergunning/composables/edit-helper'

export default defineComponent({
  name: 'exploitatie-handeling-activiteit-edit',
  props: {
    activiteit: Object as PropType<ExploitatieVanIngedeeldeInrichtingenOfActiviteitenActiviteit>,
    projectElementIndex: Number,
    activiteitIndex: Number,
    validationErrors: Map<string, string>,
  },
  setup (props, { emit }) {
    const { options, getLabel } = useOmgevingVergunningLabels()

    const { makeUnique } = useEditHelper()
    const activiteitEdit = ref(props.activiteit as ExploitatieVanIngedeeldeInrichtingenOfActiviteitenActiviteit)
    const vlarboCodeOptions = ['', ...Object.values(ExploitatieVanIngedeeldeInrichtingenOfActiviteitenActiviteitVlareboCode)]
    const risicoKlasseOptions = ['', ...Object.values(ExploitatieVanIngedeeldeInrichtingenOfActiviteitenActiviteitRisicoklasse)]
    const activiteitStatusOptions = ['', ...Object.values(ExploitatieVanIngedeeldeInrichtingenOfActiviteitenActiviteitStatus)]

    watch(() => activiteitEdit.value, (newValue) => {
      emit('activiteit-changed', { index: props.activiteitIndex, projectElementIndex: props.projectElementIndex, value: newValue })
    })

    return { options, activiteitEdit, makeUnique, getLabel, formatDate, vlarboCodeOptions, risicoKlasseOptions, activiteitStatusOptions }
  }
})
