export default function useBeroepLabels () {
  const beslissingOptions = (instantie: string | undefined): Map<string, string> => {
    return instantie
      ? (statusOptions.has(instantie) ? statusOptions.get(instantie) : new Map())
      : new Map()
  }

  const beroepsinstantieOptions = new Map([
    ['DEPUTATIE', 'Deputatie'],
    ['RAAD_VAN_STATE', 'Raad van State'],
    ['RAAD_VOOR_VERGUNNINGSBETWISTINGEN', 'Raad voor Vergunningsbetwistingen'],
    ['VLAAMSE_OVERHEID', 'Vlaamse regering'],
  ])

  const statusOptions = new Map([
    ['DEPUTATIE', new Map([
      ['LOPENDE', 'Lopende'],
      ['GEEN_BESLISSING', 'Geen (tijdige) beslissing'],
      ['GEEN_BESLISSING_NA_RAPPEL', 'Geen (tijdige) beslissing na rappel'],
      ['GEWEIGERD', 'Geweigerd'],
      ['ONGEGROND', 'Ongegrond'],
      ['ONONTVANKELIJK', 'Onontvankelijk'],
      ['VERGUND', 'Vergund'],
      ['VERNIETIGD', 'Vernietigd'],
      ['VOORWAARDELIJK_VERGUND', 'Voorwaardelijk vergund'],
      ['GEDEELTELIJK_VOORWAARDELIJK_VERGUND', 'Gedeeltelijk voorwaardelijk vergund'],
    ])],
    ['VLAAMSE_OVERHEID', new Map([
      ['LOPENDE', 'Lopende'],
      ['BEVESTIGD', 'Bevestigd'],
      ['GEEN_BESLISSING', 'Geen (tijdige) beslissing'],
      ['GEEN_BESLISSING_NA_RAPPEL', 'Geen (tijdige) beslissing na rappel'],
      ['GEWEIGERD', 'Geweigerd'],
      ['GEWIJZIGD_GEWEIGERD', 'Gewijzigd: Geweigerd'],
      ['GEWIJZIGD_VERGUND', 'Gewijzigd: Vergund'],
      ['ONONTVANKELIJK', 'Onontvankelijk'],
      ['VERGUND', 'Vergund'],
      ['VERNIETIGD', 'Vernietigd'],
      ['VOORWAARDELIJK_VERGUND', 'Voorwaardelijk vergund'],
      ['GEDEELTELIJK_VOORWAARDELIJK_VERGUND', 'Gedeeltelijk voorwaardelijk vergund'],
    ])],
    ['RAAD_VAN_STATE', new Map([
      ['LOPENDE', 'Lopende'],
      ['GESCHORST', 'Beslissing geschorst'],
      ['NIET_GESCHORST', 'Beslissing niet geschorst'],
      ['VERNIETIGD', 'Beslissing vernietigd'],
      ['NIET_VERNIETIGD', 'Beslissing niet vernietigd'],
      ['ONONTVANKELIJK', 'Onontvankelijk']
    ])],
    ['RAAD_VOOR_VERGUNNINGSBETWISTINGEN', new Map([
      ['VERWORPEN', 'Verworpen'],
      ['LOPENDE', 'Lopende'],
      ['GESCHORST', 'Beslissing geschorst'],
      ['NIET_GESCHORST', 'Beslissing niet geschorst'],
      ['VERNIETIGD', 'Beslissing vernietigd'],
      ['NIET_VERNIETIGD', 'Beslissing niet vernietigd'],
      ['DEELS_VERGUND', 'Gedeeltelijk vergund'],
      ['ONONTVANKELIJK', 'Onontvankelijk']
    ])]
  ])
  return { beroepsinstantieOptions, beslissingOptions }
}
