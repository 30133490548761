import {
  VerkavelingVanGronden,
  VerkavelingVanGrondenType,
  VerkavelingVanGrondenVervalStatus,
} from '@/infrastructure/bff-client/bff-client'
import { defineComponent, onMounted, PropType, ref, watch } from 'vue'
import useOmgevingVergunningLabels from '../../composables/omgevingsvergunning-labels'
import { formatDate } from '@/infrastructure/filters/filters'
import useEditHelper from '../../composables/edit-helper'
import { ProjectElementType } from './project-elementen-edit'
import ContentCollection from '@/components/vip-inlichting/new-version/components/content-collection/content-collection.vue'
import EventBus from '@/infrastructure/events/event-bus'
import VerkavelingVanGrondenLotInfoEdit
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/omgevingsvergunning/edit/project-elementen/verkaveling-van-gronden-lot-info-edit.vue'
import VergunningVerval
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/omgevingsvergunning/edit/vergunning-verval/vergunning-verval.vue'

export enum LocalLastenEnum{
  FINANCIELE_GELDSOM = 'FINANCIELE_GELDSOM',
  UITVOEREN_VAN_WERKEN_IN_NATURA = 'UITVOEREN_VAN_WERKEN_IN_NATURA',
  GRATIS_EIGENDOMSOVERDRACHT = 'GRATIS_EIGENDOMSOVERDRACHT',
}
export default defineComponent({
  name: 'verkaveling-van-gronden-edit',
  components: {
    VergunningVerval,
    VerkavelingVanGrondenLotInfoEdit,
    ContentCollection,
  },
  props: {
    projectElement: Object as PropType<VerkavelingVanGronden>,
    projectElementIndex: Number,
    validationErrors: Map<string, string>,
  },
  setup (props, { emit }) {
    const projectElementEdit = ref(props.projectElement as VerkavelingVanGronden)
    const renderKey = ref(0)

    const { options, getLabel } = useOmgevingVergunningLabels()
    const { makeUnique } = useEditHelper()
    const verkavelingVanGrondenTypeOptions = VerkavelingVanGrondenType
    const lastenOptions = Object.values(LocalLastenEnum)
    const vervalOptions = Object.values(VerkavelingVanGrondenVervalStatus)
    watch(
      () => projectElementEdit.value,
      (newValue) => {
        emit('project-element-changed', {
          index: props.projectElementIndex,
          type: ProjectElementType.verkaveling,
          value: newValue,
        })
      }, { deep: true }
    )

    const addLotInfo = () => {
      projectElementEdit.value.lotInformatie.push({})
      renderKey.value++
    }

    const removeLotInfo = (index: number) => {
      projectElementEdit.value.lotInformatie.splice(index, 1)
      renderKey.value++
      EventBus.$emit('close-modal', 'vl-modal-backdrop')
    }

    const handleLotInfoChanged = (lotinfoToChange: { index: number; value: any }) => {
      renderKey.value++
      projectElementEdit.value.lotInformatie[lotinfoToChange.index] = lotinfoToChange.value
    }

    onMounted(() => {
      if ((projectElementEdit.value.lotInformatie ?? []).length === 0) {
        projectElementEdit.value.lotInformatie = []
      }
    })

    return {
      projectElementEdit,
      renderKey,
      options,
      verkavelingVanGrondenTypeOptions,
      lastenOptions,
      vervalOptions,
      makeUnique,
      getLabel,
      formatDate,
      addLotInfo,
      removeLotInfo,
      handleLotInfoChanged,
    }
  },
})
