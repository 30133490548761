import { defineComponent, PropType, ref } from 'vue'
import ContentCollectionItem
  from '@/components/vip-inlichting/new-version/components/content-collection/item/content-collection-item.vue'
import { generateKey } from '@/infrastructure/helpers/general'

export interface CollectionButton {
  label: string
  action: string
  ref: string
}

export default defineComponent({
  name: 'ContentCollection',
  components: { ContentCollectionItem },
  props: {
    buttons: Array as PropType<CollectionButton[]>,
    itemCount: Number,
    buttonsTop: {
      type: Boolean,
      required: false,
      default: true
    },
    firstItemDeletable: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  setup (_, { emit }) {
    const indexToRemove = ref(-1)

    const requestRemoveByIndex = (index: number) => {
      indexToRemove.value = index
    }

    const removeItem = () => {
      emit('remove', indexToRemove.value)
      indexToRemove.value = -1
    }

    const cancelRemove = () => {
      indexToRemove.value = -1
    }

    const handleButton = (action: string) => {
      emit(action)
    }

    return {
      indexToRemove,
      generateKey,
      requestRemoveByIndex,
      removeItem,
      cancelRemove,
      handleButton
    }
  }
})
