import { defineComponent, PropType, ref, watch } from 'vue'
import {
  AdviesHogeRaadVoorHandhavingsuitvoering,
  HofVanBeroep,
  ProcesVerbaal,
  ProcesVerbaalBeslissingParket,
  RechtbankVanEersteAanleg,
  RechtbankVanEersteAanlegTypeRechtbank,
  RestHofVanCassatieUitspraak,
  Seponering,
  UitsprakenRechterlijkeInstantie,
  Vervolging
} from '@/infrastructure/bff-client/bff-client'
import useHandhavingsinstrument
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/stedenbouwkundige-overtreding/composable/handhavingsinstrument.composable'
import PvUitsprakenEdit
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/stedenbouwkundige-overtreding/edit/procedure/handhavingsinstrumenten/proces-verbaal-uitspraken/pv-uitspraken-edit.vue'
import useStedenbouwkundigeOvertredingLabels
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/stedenbouwkundige-overtreding/composable/stedenbouwkundige-overtreding-labels.composable'
import { HandhavingsinstrumentType } from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/stedenbouwkundige-overtreding/edit/procedure/procedure-edit'
import useInlichtingValidation
  from '@/components/vip-inlichting/new-version/composables/inlichting-validation.composable'
import PvUitspraakEdit
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/stedenbouwkundige-overtreding/edit/procedure/handhavingsinstrumenten/proces-verbaal-uitspraken/pv-uitspraak-edit.vue'

export enum RechterlijkeInstantie {
  HogeRaadVoorHandhavingsuitvoering = 'HogeRaadVoorHandhavingsuitvoering',
  RechtbankEersteAanlegBurgerlijk = 'RechtbankEersteAanlegBurgerlijk',
  RechtbankEersteAanlegCorrectioneel = 'RechtbankEersteAanlegCorrectioneel',
  HofVanBeroep = 'HofVanBeroep',
  HofVanCassatie = 'HofVanCassatie'
}

export interface UitspraakRechterlijkeInstantie {
  rechtbank?: RechterlijkeInstantie,
  adviesHogeRaadVoorHandhavingsuitvoering?: AdviesHogeRaadVoorHandhavingsuitvoering,
  rechtbankEersteAanleg?: RechtbankVanEersteAanleg,
  hofVanBeroep?: HofVanBeroep
  hofVanCassatie?: RestHofVanCassatieUitspraak
}

export default defineComponent({
  name: 'proces-verbaal-edit',
  components: { PvUitspraakEdit, PvUitsprakenEdit },
  props: {
    instrument: Object as PropType<ProcesVerbaal>,
    procedureIndex: Number,
    instrumentIndex: Number,
    validationErrors: Map<string, string>,
    modDisabled: Boolean
  },
  setup (props, { emit }) {
    const { makeUnique } = useHandhavingsinstrument()
    const { options, getLabel } = useStedenbouwkundigeOvertredingLabels()
    const { hasError } = useInlichtingValidation(props.validationErrors)

    const pvEdit = ref(props.instrument as ProcesVerbaal)

    const omschrijvingVorderingSuggestions = ['Betalen van een geldsom gelijk aan de meerwaarde',
      'Betalen van een meerwaarde',
      'In oorspronkelijke staat herstellen of strijdig gebruik staken',
      'Plaats in vorige staat herstellen of strijdig gebruik staken',
      'Plaats in vorige staat herstellen of strijdig gebruik staken EN betalen van een geldsom gelijk aan de meerwaarde',
      'Plaats in vorige staat herstellen of strijdig gebruik staken EN uitvoeren van aanpassingswerken',
      'Plaats in vorige staat herstellen of strijdig gebruik staken EN uitvoeren van aanpassingswerken EN betalen van een geldsom gelijk aan de meerwaarde',
      'Transactiesom betalen en regularisatievergunning bekomen',
      'Uitvoeren van aanpassingswerken',
      'Uitvoeren van aanpassingswerken EN betalen van een geldsom gelijk aan de meerwaarde',
      'Uitvoeren van bouw- of aanpassingswerken',
      'Vrijspraak',
      'Andere',]

    const handleUitsprakenChanged = (uitspraken: UitsprakenRechterlijkeInstantie) => {
      pvEdit.value.vervolging.uitsprakenRechterlijkeInstantie = uitspraken
    }

    const flattenUitspraken = (): UitspraakRechterlijkeInstantie[] => {
      const uitspraken = pvEdit.value.vervolging?.uitsprakenRechterlijkeInstantie
      if (uitspraken == null) {
        return []
      }
      const uitsprakenMapped: UitspraakRechterlijkeInstantie[] = []
      if (uitspraken.adviesHogeRaadVoorHandhavingsuitvoering != null) {
        uitsprakenMapped.push({
          rechtbank: RechterlijkeInstantie.HogeRaadVoorHandhavingsuitvoering,
          adviesHogeRaadVoorHandhavingsuitvoering: uitspraken.adviesHogeRaadVoorHandhavingsuitvoering
        })
      }
      uitsprakenMapped.push(...uitspraken.rechtbankenVanEersteAanleg?.map(uitspraak => (
        {
          rechtbank: uitspraak.typeRechtbank === RechtbankVanEersteAanlegTypeRechtbank.BURGERLIJK
            ? RechterlijkeInstantie.RechtbankEersteAanlegBurgerlijk
            : RechterlijkeInstantie.RechtbankEersteAanlegCorrectioneel,
          rechtbankEersteAanleg: uitspraak
        }
      )))
      uitsprakenMapped.push(...uitspraken.hovenVanBeroep?.map(uitspraak => ({
        rechtbank: RechterlijkeInstantie.HofVanBeroep,
        hofVanBeroep: uitspraak
      })))
      if (uitspraken.hofVanCassatieUitspraken != null) {
        uitsprakenMapped.push(...uitspraken.hofVanCassatieUitspraken?.map(uitspraak => ({
          rechtbank: RechterlijkeInstantie.HofVanCassatie,
          hofVanCassatie: uitspraak
        })))
      }
      return uitsprakenMapped
    }

    const handleBeslissingParket = (beslissingParket: ProcesVerbaalBeslissingParket) => {
      switch (beslissingParket) {
        case ProcesVerbaalBeslissingParket.GEEN_GERECHTELIJKE_VERVOLGING:
          pvEdit.value.vervolging = null
          pvEdit.value.seponering = null
          break
        case ProcesVerbaalBeslissingParket.SEPONERING:
          pvEdit.value.vervolging = null
          pvEdit.value.seponering = { reden: '' } as Seponering
          break
        case ProcesVerbaalBeslissingParket.VERVOLGING:
          pvEdit.value.vervolging = {} as Vervolging
          pvEdit.value.seponering = null
          break
      }
    }

    const addOmschrijvingVorderingSuggestion = (addedValue: string) => {
      omschrijvingVorderingSuggestions.push(addedValue)
      const tmpCopy = structuredClone(pvEdit.value)
      tmpCopy.vervolging.omschrijving = addedValue
      pvEdit.value = tmpCopy
    }

    watch(() => pvEdit.value, (newValue) => {
      emit('instrument-changed', {
        index: props.instrumentIndex,
        type: HandhavingsinstrumentType.ProcesVerbaal,
        value: newValue
      })
    })

    return {
      pvEdit,
      options,
      omschrijvingVorderingSuggestions,
      hasError,
      getLabel,
      makeUnique,
      handleUitsprakenChanged,
      handleBeslissingParket,
      flattenUitspraken,
      addOmschrijvingVorderingSuggestion
    }
  }
})
