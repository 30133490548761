import { LotInformatie, LotInformatieStatus } from '@/infrastructure/bff-client/bff-client'
import { defineComponent, PropType, ref, watch } from 'vue'
import { formatDate } from '@/infrastructure/filters/filters'
import useVerkavelingsvergunningVervalLabels
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/verkavelingsvergunning/composables/verkavelingsvergunning-labels.composable'

export default defineComponent({
  name: 'lot-informatie',
  props: {
    lotInfo: Object as PropType<LotInformatie>,
    lotIndex: Number,
    validationErrors: Map<string, string>,
  },
  setup (props, { emit }) {
    const { vervalOptions } = useVerkavelingsvergunningVervalLabels()

    const lotInfoEdit = ref(props.lotInfo as LotInformatie)
    const lotVervalOptions = LotInformatieStatus

    watch(lotInfoEdit, (newValue) => {
      emit('lot-info-changed', { lot: newValue, index: props.lotIndex })
    })

    return {
      lotInfoEdit,
      vervalOptions,
      lotVervalOptions,
      formatDate
    }
  }
})
