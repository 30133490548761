import {
  ExploitatieVanIngedeeldeInrichtingenOfActiviteiten,
  ExploitatieVanIngedeeldeInrichtingenOfActiviteitenActiviteitStatus,
  ExploitatieVanIngedeeldeInrichtingenOfActiviteitenRisicoklasse,
  ExploitatieVanIngedeeldeInrichtingenOfActiviteitenVervalStatus,
} from '@/infrastructure/bff-client/bff-client'
import { defineComponent, onMounted, PropType, ref, watch } from 'vue'
import useOmgevingVergunningLabels from '../../composables/omgevingsvergunning-labels'
import { formatDate } from '@/infrastructure/filters/filters'
import { getMilieuActiviteitenDetails } from '@/infrastructure/helpers/milieu-activiteiten-helper'
import useEditHelper from '../../composables/edit-helper'
import { ProjectElementType } from './project-elementen-edit'
import VergunningVerval
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/omgevingsvergunning/edit/vergunning-verval/vergunning-verval.vue'
import ExploitatieHandelingActiviteitenEdit
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/omgevingsvergunning/edit/project-elementen/exploitatie-handeling-activiteit-edit.vue'
import ContentCollection
  from '@/components/vip-inlichting/new-version/components/content-collection/content-collection.vue'
import EventBus from '@/infrastructure/events/event-bus'

export default defineComponent({
  name: 'exploitatie-edit',
  components: { ContentCollection, ExploitatieHandelingActiviteitenEdit, VergunningVerval },
  props: {
    projectElement: Object as PropType<ExploitatieVanIngedeeldeInrichtingenOfActiviteiten>,
    projectElementIndex: Number,
    validationErrors: Map<string, string>,
  },

  setup (props, { emit }) {
    const { options, getLabel } = useOmgevingVergunningLabels()
    const { makeUnique } = useEditHelper()
    const renderKey = ref(0)
    const projectElementEdit = ref(props.projectElement as ExploitatieVanIngedeeldeInrichtingenOfActiviteiten)
    const risicoKlasseOptions = ['', ...Object.values(ExploitatieVanIngedeeldeInrichtingenOfActiviteitenRisicoklasse)]
    const vervalOptions = Object.values(ExploitatieVanIngedeeldeInrichtingenOfActiviteitenVervalStatus)

    watch(
      () => projectElementEdit.value,
      (newValue) => {
        emit('project-element-changed', {
          index: props.projectElementIndex,
          type: ProjectElementType.exploitatie,
          value: newValue,
        })
      }, { deep: true }
    )

    const handleActiviteitChanged = (activiteitToChange: { index: number; value: any }) => {
      const copy = structuredClone(projectElementEdit.value)
      copy.activiteiten[activiteitToChange.index] = activiteitToChange.value
      projectElementEdit.value = copy
    }

    const getCategorieDescription = (categorie: string): string => {
      let catDescription = ''

      switch (categorie) {
        case 'O':
          catDescription = 'Oriënterend onderzoek verplicht bij overdracht, sluiting en faillissement'
          break
        case 'A':
          catDescription =
            'Oriënterend onderzoek verplicht bij overdracht, sluiting en faillissement, en om de twintig jaar'
          break
        case 'B':
          catDescription =
            'Oriënterend onderzoek verplicht bij overdracht, sluiting en faillissement, en om de tien jaar'
          break
        case 'S':
          catDescription =
            'De exploitant dient onder leiding van een bodemsaneringsdeskundige een oriënterend bodemonderzoek uit te laten voeren en het verslag ervan aan de OVAM te bezorgen op verschillende tijdstippen, zie Vlarem I bijlage 1'
          break
        case 'A*':
          catDescription =
            'Inrichting waarvoor: a) bij uitsluitend bovengrondse opslag conform het Bodemdecreet van 27 oktober 2006 en het VLAREBO van 14 december 2007 een oriënterend bodemonderzoek verplicht is bij overdracht, onteigening, sluiting en faillissement; b) bij ondergrondse opslag of bij combinatie van ondergrondse of bovengrondse opslag conform het Bodemdecreet van 27 oktober 2006 en het VLAREBO van 14 december 2007 een oriënterend onderzoek verplicht is bij overdracht, sluiting en faillissement en om de twintig jaar'
          break
        case 'B*':
          catDescription =
            'Inrichting waarvoor: a) bij uitsluitend bovengrondse opslag conform het Bodemdecreet van 27 oktober 2006 en het VLAREBO van 14 december 2007 een oriënterend onderzoek verplicht is bij overdracht, sluiting en faillissement en om de twintig jaar; b) bij ondergrondse opslag of bij combinatie van ondergrondse of bovengrondse opslag conform het Bodemdecreet van 27 oktober 2006 en het VLAREBO van 14 december 2007 een oriënterend onderzoek verplicht is bij overdracht, sluiting en faillissement en om de tien jaar'
          break
        case 'C':
          catDescription =
            'Oriënterend onderzoek verplicht bij overdracht, sluiting en faillissement, en om de vijf jaar'
          break
        default:
          break
      }
      return catDescription
    }

    const checkOrCross = (
      activiteitStatus: ExploitatieVanIngedeeldeInrichtingenOfActiviteitenActiviteitStatus
    ): string => {
      return activiteitStatus === ExploitatieVanIngedeeldeInrichtingenOfActiviteitenActiviteitStatus.VERGUND
        ? 'check'
        : 'cross'
    }
    const getActiviteitTekstFormatted = (activiteit: any) => {
      return getMilieuActiviteitenDetails(activiteit)
    }

    const addActiviteit = () => {
      const copy = structuredClone(projectElementEdit.value)
      copy.activiteiten.push({} as any)
      projectElementEdit.value = copy
      triggerRender()
    }

    const removeActiviteit = (indexToRemove: number) => {
      const copy = structuredClone(projectElementEdit.value)
      copy.activiteiten.splice(indexToRemove, 1)
      projectElementEdit.value = copy
      triggerRender()
      EventBus.$emit('close-modal', 'vl-modal-backdrop')
    }

    const triggerRender = () => {
      renderKey.value++
    }

    onMounted(() => {
      if ((projectElementEdit.value.activiteiten ?? []).length === 0) {
        projectElementEdit.value.activiteiten = []
      }
    })

    return {
      projectElementEdit,
      renderKey,
      options,
      vervalOptions,
      risicoKlasseOptions,
      makeUnique,
      getLabel,
      formatDate,
      handleActiviteitChanged,
      getCategorieDescription,
      checkOrCross,
      getActiviteitTekstFormatted,
      addActiviteit,
      removeActiviteit
    }
  },
})
