export default function useVervalIntrekkingLabels () {
  const statusOptions = new Map([
    ['', ''],
    ['NIET_ONDERZOCHT', 'Niet onderzocht'],
    ['NIET_VERVALLEN', 'Niet vervallen'],
    ['DEELS_VERVALLEN', 'Deels vervallen'],
    ['VERVALLEN', 'Vervallen'],
    ['INGETROKKEN', 'Ingetrokken'],
  ])

  const statusToekomstOptions = new Map([
    ['DEELS_VERVALLEN', 'Verval deels'],
    ['VERVALLEN', 'Vervalt'],
  ])

  const intrekkingPartijOptions = new Map([
    ['AANVRAGER', 'Aanvrager'],
    ['VERGUNNINGVERLENENDE_OVERHEID', 'Vergunningverlenende overheid']
  ])

  return { statusOptions, intrekkingPartijOptions, statusToekomstOptions }
}
