import { computed, defineComponent, PropType, ref, watch } from 'vue'
import useMilieuBeroepLabels
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/milieuvergunning/edit/beroepsprocedure/composable/milieu-beroep-labels.composable'
import { BeroepsprocedureMilieu } from '@/infrastructure/bff-client/bff-client'

export default defineComponent({
  name: 'beroepsprocedure-milieu',
  props: {
    inlichtingKey: String,
    validationErrors: Map<string, string>,
    saving: Boolean,
    procedure: Object as PropType<BeroepsprocedureMilieu>,
    procedureIndex: Number,
    defaultInstantie: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update'],
  setup (props, { emit }) {
    const input = ref(props.procedure as any)
    const { beroepsinstantieOptions, beslissingOptions } = useMilieuBeroepLabels()

    const optionsBeslissing = computed(() => beslissingOptions(input.value.beroepsinstantie))

    watch(input, (newValue) => {
      emit('update', { procedure: newValue, index: props.procedureIndex })
    }, { deep: true, immediate: false })

    return {
      input,
      beroepsinstantieOptions,
      optionsBeslissing
    }
  }
})
