import { render, staticRenderFns } from "./leegstaande-en-verwaarloosde-bedrijfsruimten-v2.vue?vue&type=template&id=1cd1ca1a"
import script from "./leegstaande-en-verwaarloosde-bedrijfsruimten-v2.ts?vue&type=script&lang=ts&external"
export * from "./leegstaande-en-verwaarloosde-bedrijfsruimten-v2.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports