import { defineComponent, PropType, ref, watch } from 'vue'
import { RisicogrondRubriekV2, SchrappingV2 } from '@/infrastructure/bff-client/bff-client'
import useRisicogrondLabels
  from '@/components/vip-inlichting/new-version/components/inlichtingen/milieu/risicogrond/v2/composable/risicogrond-labels.composable'
import { generateKey } from '@/infrastructure/helpers/general'

export default defineComponent({
  name: 'RisicoRubriek',
  props: {
    rubriek: Object as PropType<RisicogrondRubriekV2>,
    rubriekIndex: Number,
    validationErrors: Map<string, string>,
    saving: Boolean
  },
  setup (props, { emit }) {
    const vitoUrl = 'https://navigator.emis.vito.be/rubriekenlijst?indexId='

    const uniqueKey = ref(generateKey())
    const rubriekEdit = ref(props.rubriek as RisicogrondRubriekV2)
    const vitoUrlEdit = ref(props.rubriek.vitoUrl?.replace(vitoUrl, '') ?? '')
    const isGeschraptEdit = ref(props.rubriek.schrapping != null)

    const { options, getLabel } = useRisicogrondLabels()
    const makeUnique = (element: string) => `${element}-${uniqueKey.value}`

    watch(() => vitoUrlEdit.value, (newValue) => {
      rubriekEdit.value.vitoUrl = `${vitoUrl}${newValue}`
    })

    watch(() => isGeschraptEdit.value, (newValue, oldValue) => {
      if (newValue !== oldValue && newValue) {
        rubriekEdit.value.schrapping = {} as SchrappingV2
      } else if (!newValue) {
        rubriekEdit.value.schrapping = null
      }
    })

    watch(() => rubriekEdit.value, (newValue) => {
      emit('updated', { rubriek: newValue, index: props.rubriekIndex })
    })

    return {
      rubriekEdit,
      vitoUrlEdit,
      isGeschraptEdit,
      options,
      getLabel,
      makeUnique
    }
  }
})
