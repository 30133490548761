/* eslint-disable */
import {defineComponent, PropType, readonly} from 'vue'
import useOmgevingVergunningLabels from '../../composables/omgevingsvergunning-labels'
import { formatDate } from '@/infrastructure/filters/filters'
import { StedenbouwkundigeHandeling } from '@/infrastructure/bff-client/bff-client'

export default defineComponent({
  name: 'stedenbouwkundige-handeling-preview',
  props: {
    previewData: Object as PropType<StedenbouwkundigeHandeling>
  },
  setup (props) {
    const { getLabel, vervalTekst } = useOmgevingVergunningLabels()
    const preview = readonly(props.previewData as StedenbouwkundigeHandeling)

    return { preview, getLabel, formatDate, vervalTekst }
  }
})
