import { formatDate } from '@/infrastructure/filters/filters'

export default function useOmgevingVergunningLabels () {
  const options =
  new Map<string, Map<string, string>>([
    ['lopendeProcedure', new Map<string, string>([
      ['GEEN_LOPENDE_PROCEDURE', 'Geen lopende procedure'],
      ['NIET_GEKEND', 'Niet gekend'],
      ['BEROEP_RVS', 'Beroep Raad van State'],
      ['BEROEP_RVVB', 'Beroep Raad voor vergunningsbetwisting'],
      ['BEROEP_RVVB_NA_RVS', 'Beroep Raad voor vergunningsbetwisting na Raad van State'],
      ['EERSTE_AANLEG_CBS', 'Eerste aanleg CBS'],
      ['EERSTE_AANLEG_DEPUTATIE', 'Eerste aanleg deputatie'],
      ['EERSTE_AANLEG_VLAAMSE_REGERING', 'Eerste aanleg Vlaamse Regering'],
      ['EERSTE_AANLEG_VLAAMSE_REGERING_NA_RVVB', 'Eerste aanleg Vlaamse Regering na Raad voor Vergunningsbetwisting'],
      ['TWEEDE_AANLEG_DEPUTATIE', 'Tweede aanleg deputatie'],
      ['TWEEDE_AANLEG_VLAAMSE_REGERING', 'Tweede aanleg Vlaamse Regering'],
      ['TWEEDE_AANLEG_VLAAMSE_REGERING_NA_RVVB', 'Tweede aanleg Vlaamse Regering na Raad voor Vergunningsbetwisting'],

    ])],
    ['administratieveInstantie', new Map<string, string>([
      ['DEPUTATIE', 'Deputatie'],
      ['AGENTSCHAP_VOOR_NATUUR_EN_BOS', 'Agentschap voor Natuur en Bos'],
      ['COLLEGE_BURGEMEESTER_SCHEPENEN', 'College van Burgemeester en Schepenen'],
      ['OVAM', 'OVAM'],
      ['VLAAMSE_REGERING', 'Vlaamse Regering'],
      ['VLAAMS_MINISTER_VAN_LEEFMILIEU', 'Vlaams Minister van Leefmilieu'],
      ['RAAD_VAN_STATE', 'Raad van State'],
      ['RAAD_VOOR_VERGUNNINGSBETWISTING', 'Raad voor Vergunningsbetwisting'],
    ])],
    ['beslissingsType', new Map<string, string>([
      ['GEEN_BESLISSING', 'Geen beslissing'],
      ['VOORWAARDELIJK_VERGUND', 'Voorwaardelijk vergund'],
      ['AKTENAME', 'Aktename'],
      ['GEEN_AKTENAME', 'Geen aktename'],
      ['GEDEELTELIJK_VERGUND', 'Gedeeltelijk vergund'],
      ['TIJDELIJK_VERGUND', 'Tijdelijk vergund'],
      ['VERGUND', 'Vergund'],
      ['GEDEELTELIJK_VOORWAARDELIJK_VERGUND', 'Gedeeltelijk voorwaardelijk vergund'],
      ['ONGEGROND_NIETS_RECHTSGELDIG', 'Ongegrond, niets rechtsgeldig'],
      ['GEWEIGERD', 'Geweigerd'],
      ['GESCHORST', 'Beslissing geschorst'],
      ['DEELS_VERGUND', 'Deels vergund'],
      ['ONVOLLEDIG_ONONTVANKELIJK', 'Onvolledig en onontvankelijk'],
      ['STILZWIJGENDE_AKTENAME', 'Stilzwijgende Aktename'],
      ['STILZWIJGENDE_BEVESTIGING_1STE_AANLEG', 'Stilzwijgende bevestiging 1ste aanleg'],
      ['STILZWIJGEND_GEWEIGERD', 'Stilzwijgend geweigerd'],
      ['GEEN_BESLISSING_NA_RAPPEL', 'Geen beslissing na rappel'],
      ['ZONDER_VOORWERP', 'Zonder voorwerp'],
      ['BIJSTELLING', 'Bijstelling van een bestaande verkaveling'],
      ['NIEUW', 'Aanvraag nieuwe verkaveling'],
      ['NIET_VERNIETIGD', 'Niet vernietigd'],
      ['VERNIETIGD', 'Vernietigd'],
      ['NIET_GESCHORST', 'Niet Geschorst'],
      ['ONVOLLEDIG_ONONTVANKELIJK', 'Onvolledig en onontvankelijk'],
    ])],
    ['vervalStatus', new Map<string, string>([
      ['VERVALLEN', 'Vervallen'],
      ['NIET_VERVALLEN', 'Niet vervallen'],
      ['NIET_ONDERZOCHT', 'Verval van de vergunning zal onderzocht worden als hierom verzocht wordt'],
      ['INGETROKKEN', 'Ingetrokken'],
      ['DEELS_VERVALLEN', 'Deels vervallen'],
      ['HERZIEN_BPA_RUP', 'Herzien door BPA of RUP'],
      ['HERZIEN_COLLEGE', 'Herzien door college'],
      ['OPGEHEVEN_BPA_RUP', 'Opgeheven door BPA of RUP'],
      ['OPGEHEVEN_COLLEGE', 'Opgeheven door college'],
      ['VERLENGD', 'Verlengd'],
      ['VERVANGEN', 'Vervangen'],
      ['VRIJSTELLING', 'Vrijstelling'],
      ['VERZAAKT', 'Verzaakt']
    ])],
    ['vervalStatusToekomst', new Map<string, string>([
      ['VERVALLEN', 'Vervalt'],
      ['NIET_VERVALLEN', 'Niet vervallen'],
      ['NIET_ONDERZOCHT', 'Verval van de vergunning zal onderzocht worden als hierom verzocht wordt'],
      ['INGETROKKEN', 'Ingetrokken'],
      ['DEELS_VERVALLEN', 'Vervalt deels'],
      ['HERZIEN_BPA_RUP', 'Wordt herzien door BPA of RUP'],
      ['HERZIEN_COLLEGE', 'Herzien door college'],
      ['OPGEHEVEN_BPA_RUP', 'Wordt opgeheven door BPA of RUP'],
      ['OPGEHEVEN_COLLEGE', 'Wordt opgeheven door college'],
      ['VERLENGD', 'Wordt verlengd'],
      ['VERVANGEN', 'Wordt vervangen'],
      ['VRIJSTELLING', 'Vrijstelling'],
      ['VERZAAKT', 'Wordt verzaakt']
    ])],
    ['vervalStatusOptions', new Map<string, string>([
      ['NIET_ONDERZOCHT', 'Niet onderzocht'],
      ['NIET_VERVALLEN', 'Niet vervallen'],
      ['VERVALLEN', 'Vervallen'],
      ['INGETROKKEN', 'Ingetrokken'],
      ['DEELS_VERVALLEN', 'Deels vervallen'],
      ['HERZIEN_BPA_RUP', 'Herzien door BPA of RUP'],
      ['HERZIEN_COLLEGE', 'Herzien door college'],
      ['OPGEHEVEN_BPA_RUP', 'Opgeheven door BPA of RUP'],
      ['OPGEHEVEN_COLLEGE', 'Opgeheven door college'],
      ['VERLENGD', 'Verlengd'],
      ['VERVANGEN', 'Vervangen'],
      ['VRIJSTELLING', 'Vrijstelling'],
      ['VERZAAKT', 'Verzaakt']
    ])],
    ['kleinhandelsActiviteitType', new Map<string, string>([
      ['VERKOOP_VAN_GOEDEREN_VOOR_PERSOONSUITRUSTING', 'Goederen voor persoonsuitrusting'],
      ['VERKOOP_VAN_VOEDING', 'Voeding'],
      ['VERKOOP_VAN_ANDERE_NIET_VOLUMINEUZE_GOEDEREN', 'Andere niet-volumineuze goederen'],
      ['VERKOOP_VAN_ANDERE_PRODUCTEN', 'Andere producten'],
      ['VERKOOP_VAN_ANDERE_VOLUMINEUZE_GOEDEREN', 'Andere volumineuze goederen'],
      ['VERKOOP_VAN_PLANTEN_BLOEMEN_EN_GOEDEREN_VOOR_LAND_EN_TUINBOUW', 'Planten, bloemen en goederen voor land- en tuinbouw'],
      ['VERKOOP_VAN_VERVOERS_EN_TRANSPORTMIDDELEN', 'Vervoers- en transportmiddelen'],
    ])],
    ['exploitatieKlasse', new Map<string, string>([
      ['', ''],
      ['KLASSE_1', 'Klasse 1'],
      ['KLASSE_2', 'Klasse 2'],
      ['KLASSE_3', 'Klasse 3'],
    ])],
    ['lotInfoStatus', new Map<string, string>([
      ['NIET_ONDERZOCHT', 'Niet onderzocht'],
      ['NIET_VERVALLEN', 'Niet vervallen'],
      ['VERVALLEN', 'Vervallen'],
    ])],
    ['lotInfoStatusToekomst', new Map<string, string>([
      ['NIET_ONDERZOCHT', 'Niet onderzocht'],
      ['NIET_VERVALLEN', 'Niet vervallen'],
      ['VERVALLEN', 'Vervalt op']
    ])],
    ['lasten', new Map<string, string>([
      ['FINANCIELE_GELDSOM', 'Geldsom betalen'],
      ['GRATIS_EIGENDOMSOVERDRACHT', 'Eigendom overdragen'],
      ['UITVOEREN_VAN_WERKEN_IN_NATURA', 'Werken uitvoeren'],
    ])],
    ['beslissingType', new Map<string, string>([
      ['BESLISSING_OVER_DE_MELDING', 'Beslissing over de melding'],
      ['BESLISSING_IN_EERSTE_ADMINISTRATIEVE_AANLEG', 'Beslissing in eerste administratieve aanleg'],
      ['BESLISSING_IN_TWEEDE_ADMINISTRATIEVE_AANLEG', 'Beslissing in tweede administratieve aanleg'],
      ['ARREST_VAN_RAAD_VAN_STATE', 'Raad van State'],
      ['ARREST_VAN_RAAD_VOOR_VERGUNNINGBETWISTING', 'Raad voor Vergunningsbetwisting'],
    ])],
    ['grondType', new Map<string, string>([
      ['BIJSTELLING', 'Bijstelling'],
      ['NIEUW', 'Nieuw'],
    ])],
    ['vlarboCode', new Map<string, string>([
      ['CATEGORIE_A', 'Vlarebo Categorie A'],
      ['CATEGORIE_A_ASTERISK', 'Vlarebo Categorie A*'],
      ['CATEGORIE_A_ASTERISK_I', 'Vlarebo Categorie A* Inventarisatieplicht'],
      ['CATEGORIE_A_ASTERISK_I', 'Vlarebo Categorie A* Inventarisatieplicht'],
      ['CATEGORIE_A_ASTERISK_S', 'Vlarebo Categorie A* Situatierapport'],
      ['CATEGORIE_A_I', 'Vlarebo Categorie A Inventarisatieplicht'],
      ['CATEGORIE_A_S', 'Vlarebo Categorie A Situatierapport'],
      ['CATEGORIE_B', 'Vlarebo Categorie B'],
      ['CATEGORIE_B_ASTERISK_I', 'Vlarebo Categorie B* Inventarisatieplicht'],
      ['CATEGORIE_B_ASTERISK', 'Vlarebo Categorie B*'],
      ['CATEGORIE_B_ASTERISK_S', 'Vlarebo Categorie B* Situatierapport'],
      ['CATEGORIE_B_I', 'Vlarebo Categorie B Inventarisatieplicht'],
      ['CATEGORIE_B_S', 'Vlarebo Categorie B Situatierapport'],
      ['CATEGORIE_C', 'Vlarebo Categorie C'],
      ['CATEGORIE_I', 'Vlarebo Categorie I'],
      ['CATEGORIE_O', 'Vlarebo Categorie O'],
      ['CATEGORIE_S', 'Vlarebo Categorie S'],
      ['CATEGORIE_O_I', 'Vlarebo Categorie O Inventarisatieplicht'],
      ['CATEGORIE_O_S', 'Vlarebo Categorie O Situatierapport'],
      ['NIET_GEKEND', 'Niet gekend'],
      ['NIET_GEVONDEN', 'Niet gevonden'],
    ])],
    ['vlarboCodeDescription', new Map<string, string>([
      ['CATEGORIE_A', 'Oriënterend onderzoek verplicht bij overdracht, sluiting en faillissement, en om de twintig jaar'],
      ['CATEGORIE_A_I', 'Oriënterend onderzoek verplicht bij overdracht, sluiting en faillissement, en om de twintig jaar (Inventarisatieplicht)'],
      ['CATEGORIE_A_S', 'Oriënterend onderzoek verplicht bij overdracht, sluiting en faillissement, en om de twintig jaar (Situatierapport)'],
      ['CATEGORIE_A_ASTERISK', 'Inrichting waarvoor: a) bij uitsluitend bovengrondse opslag conform het Bodemdecreet van 27 oktober 2006 en het VLAREBO van 14 december 2007 een oriënterend bodemonderzoek verplicht is bij overdracht, onteigening, sluiting en faillissement; b) bij ondergrondse opslag of bij combinatie van ondergrondse of bovengrondse opslag conform het Bodemdecreet van 27 oktober 2006 en het VLAREBO van 14 december 2007 een oriënterend onderzoek verplicht is bij overdracht, sluiting en faillissement en om de twintig jaar'],
      ['CATEGORIE_A_ASTERISK_I', 'Inrichting waarvoor: a) bij uitsluitend bovengrondse opslag conform het Bodemdecreet van 27 oktober 2006 en het VLAREBO van 14 december 2007 een oriënterend bodemonderzoek verplicht is bij overdracht, onteigening, sluiting en faillissement; b) bij ondergrondse opslag of bij combinatie van ondergrondse of bovengrondse opslag conform het Bodemdecreet van 27 oktober 2006 en het VLAREBO van 14 december 2007 een oriënterend onderzoek verplicht is bij overdracht, sluiting en faillissement en om de twintig jaar (Inventarisatieplicht)'],
      ['CATEGORIE_A_ASTERISK_S', 'Inrichting waarvoor: a) bij uitsluitend bovengrondse opslag conform het Bodemdecreet van 27 oktober 2006 en het VLAREBO van 14 december 2007 een oriënterend bodemonderzoek verplicht is bij overdracht, onteigening, sluiting en faillissement; b) bij ondergrondse opslag of bij combinatie van ondergrondse of bovengrondse opslag conform het Bodemdecreet van 27 oktober 2006 en het VLAREBO van 14 december 2007 een oriënterend onderzoek verplicht is bij overdracht, sluiting en faillissement en om de twintig jaar (Situatierapport )'],
      ['CATEGORIE_B', 'Oriënterend onderzoek verplicht bij overdracht, sluiting en faillissement, en om de tien jaar'],
      ['CATEGORIE_B_ASTERISK', 'Inrichting waarvoor: a) bij uitsluitend bovengrondse opslag conform het Bodemdecreet van 27 oktober 2006 en het VLAREBO van 14 december 2007 een oriënterend onderzoek verplicht is bij overdracht, sluiting en faillissement en om de twintig jaar; b) bij ondergrondse opslag of bij combinatie van ondergrondse of bovengrondse opslag conform het Bodemdecreet van 27 oktober 2006 en het VLAREBO van 14 december 2007 een oriënterend onderzoek verplicht is bij overdracht, sluiting en faillissement en om de tien jaar'],
      ['CATEGORIE_B_ASTERISK_I', 'Inrichting waarvoor: a) bij uitsluitend bovengrondse opslag conform het Bodemdecreet van 27 oktober 2006 en het VLAREBO van 14 december 2007 een oriënterend onderzoek verplicht is bij overdracht, sluiting en faillissement en om de twintig jaar; b) bij ondergrondse opslag of bij combinatie van ondergrondse of bovengrondse opslag conform het Bodemdecreet van 27 oktober 2006 en het VLAREBO van 14 december 2007 een oriënterend onderzoek verplicht is bij overdracht, sluiting en faillissement en om de tien jaar (Inventarisatieplicht)'],
      ['CATEGORIE_B_ASTERISK_S', 'Inrichting waarvoor: a) bij uitsluitend bovengrondse opslag conform het Bodemdecreet van 27 oktober 2006 en het VLAREBO van 14 december 2007 een oriënterend onderzoek verplicht is bij overdracht, sluiting en faillissement en om de twintig jaar; b) bij ondergrondse opslag of bij combinatie van ondergrondse of bovengrondse opslag conform het Bodemdecreet van 27 oktober 2006 en het VLAREBO van 14 december 2007 een oriënterend onderzoek verplicht is bij overdracht, sluiting en faillissement en om de tien jaar (Situatierapport)'],
      ['CATEGORIE_B_I', 'Oriënterend onderzoek verplicht bij overdracht, sluiting en faillissement, en om de tien jaar (Inventarisatieplicht)'],
      ['CATEGORIE_B_S', 'Oriënterend onderzoek verplicht bij overdracht, sluiting en faillissement, en om de tien jaar (Situatierapport)'],
      ['CATEGORIE_C', 'Oriënterend onderzoek verplicht bij overdracht, sluiting en faillissement, en om de vijf jaar'],
      ['CATEGORIE_O', 'Oriënterend onderzoek verplicht bij overdracht, sluiting en faillissement'],
      ['CATEGORIE_S', 'De exploitant dient onder leiding van een bodemsaneringsdeskundige een oriënterend bodemonderzoek uit te laten voeren en het verslag ervan aan de OVAM te bezorgen op verschillende tijdstippen, zie Vlarem I bijlage 1'],
      ['CATEGORIE_O_I', 'Oriënterend onderzoek verplicht bij overdracht, sluiting en faillissement (Inventarisatieplicht)'],
      ['CATEGORIE_O_S', 'Oriënterend onderzoek verplicht bij overdracht, sluiting en faillissement (Situatierapport)'],
      ['NIET_GEKEND', 'Niet gekend'],
      ['NIET_GEVONDEN', 'Niet gevonden'],
      ['CATEGORIE_I', 'Vlarebo Categorie I'],
    ])]
  ])

  const getLabel = (optionKey: string, valueKey: string): string => {
    const list = options.get(optionKey)
    if (list.has(valueKey)) {
      return list.get(valueKey)
    } else {
      return valueKey
    }
  }

  const vervalTekst = (status: string, datum?: Date, lotInfo?: boolean): string => {
    let previewText = ''
    const vervalDate = new Date(datum ?? new Date())
    const today = new Date()
    // Set the time part to 00:00:00 to ignore the time
    vervalDate.setHours(0, 0, 0, 0)
    today.setHours(0, 0, 0, 0)
    if (vervalDate > today) {
      previewText = getLabel(lotInfo ? 'lotInfoStatusToekomst' : 'vervalStatusToekomst', status)
    } else {
      previewText = getLabel(lotInfo ? 'lotInfoStatus' : 'vervalStatus', status)
    }
    if (datum) {
      previewText += ` op ${formatDate(datum)}`
    }
    return previewText
  }

  return { options, getLabel, vervalTekst }
}
