import { computed, defineComponent, PropType, ref, watch } from 'vue'
import {
  StedenbouwkundigVerval,
  StedenbouwkundigVervalStatus,
  VipApiIntrekking,
  VipApiIntrekkingPartijType
} from '@/infrastructure/bff-client/bff-client'
import useVervalIntrekkingLabels
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/shared/verval-intrekking/composable/verval-intrekking-labels'

export interface VervalIntrekkingInput {
  status?: StedenbouwkundigVervalStatus
  datum?: Date | undefined
  motivering?: string | undefined
  partij?: VipApiIntrekkingPartijType
}

export function mapVervalIntrekkingInput (verval?: StedenbouwkundigVerval, intrekking?: VipApiIntrekking): VervalIntrekkingInput {
  return {
    status: verval != null ? verval.status : intrekking != null ? StedenbouwkundigVervalStatus.INGETROKKEN : null,
    datum: verval != null ? verval.datum : intrekking != null ? intrekking.datum : null,
    motivering: verval != null ? verval.motivering : intrekking != null ? intrekking.motivering : null,
    partij: intrekking != null ? intrekking.partij : null
  }
}

export default defineComponent({
  name: 'VervalIntrekking',
  props: {
    inlichtingKey: String,
    validationErrors: Map<string, string>,
    saving: Boolean,
    value: Object as PropType<VervalIntrekkingInput>,
    hideVervalMotivering: {
      type: Boolean,
      required: false,
      default: false
    },
    overrideStatusOptions: {
      type: Map<string, string>,
      required: false,
      default: null
    }
  },
  setup (props, { emit }) {
    const { statusOptions, intrekkingPartijOptions } = useVervalIntrekkingLabels()

    const input = ref(props.value as VervalIntrekkingInput)

    const isVervallen = computed(() => input.value.status && input.value.status !== 'NIET_VERVALLEN' && input.value.status !== 'NIET_ONDERZOCHT')
    const isNietVervallen = computed(() => input.value.status === 'NIET_VERVALLEN' || input.value.status === 'NIET_ONDERZOCHT')
    const isIngetrokken = computed(() => input.value.status === 'INGETROKKEN')
    const hideMotivering = computed(() => props.hideVervalMotivering && !isIngetrokken.value)

    const motiveringLabel = computed(() => {
      switch (input.value.status) {
        case 'INGETROKKEN':
          return 'Motivering intrekking'
        case 'DEELS_VERVALLEN':
        case 'VERVALLEN':
          return 'Motivering verval'
        case 'NIET_ONDERZOCHT':
          return 'Motivering niet onderzocht'
        case 'NIET_VERVALLEN':
          return 'Motivering niet-verval'
        default:
          return 'Motivering'
      }
    })

    const ignoreInputWatch = ref(false)

    watch(() => input.value.status, (newValue, oldValue) => {
      ignoreInputWatch.value = true
      if (newValue === 'NIET_VERVALLEN' || newValue === 'NIET_ONDERZOCHT') {
        input.value.datum = null
      }
      if (oldValue === 'INGETROKKEN') {
        input.value.partij = null
      }
      ignoreInputWatch.value = false
      emit('input', input.value)
    })

    watch(() => input.value.datum, () => {
      if (ignoreInputWatch.value) {
        return
      }
      emit('input', input.value)
    })

    watch(() => input.value.motivering, () => {
      emit('input', input.value)
    })

    watch(() => input.value.partij, () => {
      if (ignoreInputWatch.value) {
        return
      }
      emit('input', input.value)
    })

    return {
      input,
      isVervallen,
      isNietVervallen,
      isIngetrokken,
      motiveringLabel,
      statusOptions,
      intrekkingPartijOptions,
      hideMotivering
    }
  }
})
