import { defineComponent, PropType, readonly } from 'vue'
import useOmgevingVergunningLabels from '../../composables/omgevingsvergunning-labels'
import { formatDate } from '@/infrastructure/filters/filters'
import { WijzigingVanKleineLandschapselementenOfVegetatie } from '@/infrastructure/bff-client/bff-client'

export default defineComponent({
  name: 'wijziging-omgeving-preview',
  props: {
    previewData: Object as PropType<WijzigingVanKleineLandschapselementenOfVegetatie>
  },
  setup (props) {
    const { options, getLabel } = useOmgevingVergunningLabels()
    const preview = readonly(props.previewData as WijzigingVanKleineLandschapselementenOfVegetatie)
    return { options, preview, getLabel, formatDate }
  }
})
