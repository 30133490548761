import { LotInformatie, LotInformatieStatus, VerkavelingVanGrondenType } from '@/infrastructure/bff-client/bff-client'
import { computed, defineComponent, PropType, ref, watch } from 'vue'
import useOmgevingVergunningLabels from '../../composables/omgevingsvergunning-labels'
import { formatDate } from '@/infrastructure/filters/filters'
import useEditHelper from '../../composables/edit-helper'

export default defineComponent({
  name: 'verkaveling-van-gronden-lot-info-edit',
  props: {
    lotInfo: Object as PropType<LotInformatie>,
    projectElementIndex: Number,
    lotIndex: Number,
    validationErrors: Map<string, string>,
  },
  setup (props, { emit }) {
    const { options, getLabel } = useOmgevingVergunningLabels()

    const { makeUnique } = useEditHelper()
    const lotInfoEdit = ref(props.lotInfo as LotInformatie)
    const verkavelingVanGrondenTypeOptions = VerkavelingVanGrondenType
    const vervalOptions = LotInformatieStatus

    const motiveringLabel = computed(() => {
      switch (lotInfoEdit.value.status?.toString()) {
        case 'VERVALLEN':
          return 'Motivering verval'
        case 'NIET_ONDERZOCHT':
          return 'Motivering niet onderzocht'
        case 'NIET_VERVALLEN':
          return 'Motivering niet-verval'
        default:
          return 'Motivering'
      }
    })

    watch(() => lotInfoEdit.value, (newValue) => {
      emit('lot-info-changed', { index: props.lotIndex, value: newValue })
    })

    return {
      options,
      lotInfoEdit,
      verkavelingVanGrondenTypeOptions,
      vervalOptions,
      motiveringLabel,
      makeUnique,
      getLabel,
      formatDate,
    }
  }
})
