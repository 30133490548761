import { defineComponent, PropType, ref, watch } from 'vue'
import { BijkomendeDocumentatie } from '@/infrastructure/bff-client/bff-client'
import BijkomendeDocumentatieComponent
  from '@/components/vip-inlichting/new-version/components/inlichtingen/overheidsplannen/gewestplan/v2/bijkomende-documentatie/bijkomende-documentatie.vue'
import EventBus from '@/infrastructure/events/event-bus'
import ContentCollection
  from '@/components/vip-inlichting/new-version/components/content-collection/content-collection.vue'

export default defineComponent({
  name: 'bijkomende-documentaties',
  components: { ContentCollection, BijkomendeDocumentatie: BijkomendeDocumentatieComponent },
  props: {
    documentaties: Array as PropType<BijkomendeDocumentatie[]>,
    modDisabled: Boolean,
    validationErrors: Map<string, string>,
    inlichtingKey: String
  },
  setup (props, { emit }) {
    const indexToRemove = ref(null)
    const documentatiesInput = ref(props.documentaties ?? [] as BijkomendeDocumentatie[])

    const addDocumentatie = () => {
      documentatiesInput.value.push({} as BijkomendeDocumentatie)
    }

    const makeUnique = (index: number) => {
      return 'documentatie-' + index.toString() + (Math.floor(Math.random() * (1000000 - 1 + 1) + 1)).toString()
    }

    const requestRemoveByIndex = (index: number) => {
      indexToRemove.value = index
    }

    const removeByIndex = (index: number) => {
      documentatiesInput.value.splice(index, 1)
      indexToRemove.value = null
      EventBus.$emit('close-modal', 'vl-modal-backdrop')
    }

    const cancelRemove = () => {
      indexToRemove.value = null
    }

    const handleDocumentatieUpdated = (index: number, documentatie: BijkomendeDocumentatie) => {
      documentatiesInput.value[index] = documentatie
    }

    watch(() => documentatiesInput.value, (newValue) => {
      emit('documentaties-updated', newValue)
    }, { deep: true })

    return {
      indexToRemove,
      documentatiesInput,
      addDocumentatie,
      makeUnique,
      requestRemoveByIndex,
      removeByIndex,
      cancelRemove,
      handleDocumentatieUpdated,
    }
  }
})
