import { defineComponent, PropType, ref } from 'vue'
import useHandhavingsinstrument
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/stedenbouwkundige-overtreding/composable/handhavingsinstrument.composable'
import useStedenbouwkundigeOvertredingLabels
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/stedenbouwkundige-overtreding/composable/stedenbouwkundige-overtreding-labels.composable'
import useInlichtingValidation
  from '@/components/vip-inlichting/new-version/composables/inlichting-validation.composable'
import { UitspraakRechterlijkeInstantie } from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/stedenbouwkundige-overtreding/edit/procedure/handhavingsinstrumenten/proces-verbaal-edit'
import { debounce } from 'lodash'

export default defineComponent({
  name: 'pv-uitspraken-edit',
  props: {
    uitspraak: Object as PropType<UitspraakRechterlijkeInstantie>,
    procedureIndex: Number,
    instrumentIndex: Number,
    uitspraakIndex: Number,
    validationErrors: Map<string, string>,
    modDisabled: Boolean
  },
  setup (props, { emit }) {
    const { makeUnique } = useHandhavingsinstrument()
    const { options, getLabel } = useStedenbouwkundigeOvertredingLabels()
    const { hasError } = useInlichtingValidation(props.validationErrors)

    const uitspraakEdit = ref(props.uitspraak as UitspraakRechterlijkeInstantie)

    const uitspraakChanged = debounce(() => {
      emit('changed', { uitspraakIndex: props.uitspraakIndex, uitspraak: uitspraakEdit.value })
    }, 500)

    return {
      uitspraakEdit,
      options,
      hasError,
      makeUnique,
      getLabel,
      uitspraakChanged
    }
  }
})
