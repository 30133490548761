import { computed, defineComponent, onBeforeMount, PropType, ref } from 'vue'
import { formatDate, formatNumber } from '@/infrastructure/filters/filters'
import useStedenbouwkundigeOvertredingLabels
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/stedenbouwkundige-overtreding/composable/stedenbouwkundige-overtreding-labels.composable'
import {
  Document,
  Inbreuk,
  Misdrijf,
  Overtreding,
  StedenbouwkundigeOvertredingInput,
  StedenbouwkundigeOvertredingInputGerelateerdeWetgeving
} from '@/infrastructure/bff-client/bff-client'
import ProcedureEdit
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/stedenbouwkundige-overtreding/edit/procedure/procedure-edit.vue'
import ContentCollection
  from '@/components/vip-inlichting/new-version/components/content-collection/content-collection.vue'
import EventBus from '@/infrastructure/events/event-bus'

export enum ProcedureType {
  Misdrijf = 'misdrijf',
  Inbreuk = 'inbreuk'
}
export interface Procedure {
  type: ProcedureType,
  procedure: Misdrijf | Inbreuk,
  procedureIndex: number
}

export default defineComponent({
  name: 'stedenbouwkundige-overtreding-edit',
  components: {
    ContentCollection,
    ProcedureEdit
  },
  props: {
    input: Object as PropType<StedenbouwkundigeOvertredingInput>,
    bijlagen: Array as PropType<Document[]>,
    inlichtingId: String,
    dossierId: String,
    inlichtingKey: String,
    validationErrors: Map<string, string>,
    saving: Boolean
  },
  setup (props, { emit }) {
    const { options, getLabel } = useStedenbouwkundigeOvertredingLabels()

    const gerelateerdeWetgevingOption1 = computed(() => Array.from(options.get('gerelateerdeWetgeving').keys())[0])
    const gerelateerdeWetgevingOption2 = computed(() => Array.from(options.get('gerelateerdeWetgeving').keys())[1])
    const inlichtingInput = ref(props.input)
    const overtreding = ref(props.input.overtreding ?? {} as Overtreding)

    const procedureItems = computed(() => {
      const items = [] as Procedure[]
      if (inlichtingInput.value.misdrijven != null) {
        items.push(...(inlichtingInput.value.misdrijven.map((misdrijf, index) => { return { type: ProcedureType.Misdrijf, procedure: misdrijf, procedureIndex: index } as Procedure })))
      }
      if (inlichtingInput.value.inbreuken != null) {
        items.push(...(inlichtingInput.value.inbreuken?.map((inbreuk, index) => { return { type: ProcedureType.Inbreuk, procedure: inbreuk, procedureIndex: index } as Procedure })))
      }
      return items
    })

    const naDecreet = computed(() => props.input.gerelateerdeWetgeving === StedenbouwkundigeOvertredingInputGerelateerdeWetgeving.NA_OMGEVINGSVERGUNNINGENDECREET)

    const addProcedureButtons = computed(() => {
      const buttonList = [{ label: 'Misdrijf toevoegen', action: 'addMisdrijf', ref: 'add-misdrijf' }]
      if (naDecreet.value) {
        buttonList.push({ label: 'Inbreuk toevoegen', action: 'addInbreuk', ref: 'add-inbreuk' })
      }
      return buttonList
    })

    const gerelateerdeWetgevingLabel = (key: string) => options.get('gerelateerdeWetgeving').get(key)

    const bijlageUploaded = (bijlagen: File[]) => {
      emit('bijlage-uploaded', bijlagen)
    }

    const handleOvertreding = () => {
      if (overtreding.value.status != null && overtreding.value.toelichting == null && overtreding.value.datum == null) {
        inlichtingInput.value.overtreding = null
        return
      }
      inlichtingInput.value.overtreding = overtreding.value
    }

    const bijlageDeleted = (bijlageId: string) => {
      emit('bijlage-deleted', bijlageId)
    }

    const addMisdrijf = () => {
      inlichtingInput.value.misdrijven.push({} as Misdrijf)
    }

    const addInbreuk = () => {
      inlichtingInput.value.inbreuken.push({ verslagVanVaststelling: { } } as Inbreuk)
    }

    const handleProcedureUpdated = (procedure: Procedure) => {
      if (procedure.type === ProcedureType.Misdrijf) {
        const tmpCopy = inlichtingInput.value.misdrijven.slice()
        tmpCopy[procedure.procedureIndex] = procedure.procedure as Misdrijf
        inlichtingInput.value.misdrijven = tmpCopy
      } else {
        const tmpCopy = inlichtingInput.value.inbreuken.slice()
        tmpCopy[procedure.procedureIndex] = procedure.procedure as Inbreuk
        inlichtingInput.value.inbreuken = tmpCopy
      }
    }

    const removeProcedure = (index: number) => {
      if (index < inlichtingInput.value.misdrijven.length) {
        inlichtingInput.value.misdrijven.splice(index, 1)
      } else {
        inlichtingInput.value.inbreuken.splice(index - inlichtingInput.value.misdrijven.length, 1)
      }
      EventBus.$emit('close-modal', 'vl-modal-backdrop')
    }

    const getInbreukIndex = (index: number) => {
      return inlichtingInput.value.misdrijven.length + index
    }

    onBeforeMount(() => {
      if (inlichtingInput.value.misdrijven == null) {
        inlichtingInput.value.misdrijven = []
      }
      if (inlichtingInput.value.inbreuken == null) {
        inlichtingInput.value.inbreuken = []
      }
    })

    return {
      inlichtingInput,
      overtreding,
      options,
      gerelateerdeWetgevingOption1,
      gerelateerdeWetgevingOption2,
      addProcedureButtons,
      procedureItems,
      formatNumber,
      getLabel,
      formatDate,
      gerelateerdeWetgevingLabel,
      bijlageUploaded,
      handleOvertreding,
      bijlageDeleted,
      handleProcedureUpdated,
      addInbreuk,
      addMisdrijf,
      getInbreukIndex,
      removeProcedure,
      ProcedureType
    }
  }
})
