export default function useMilieuVergunningLabels () {
  const options =
  new Map<string, Map<string, string>>([
    ['instantie', new Map<string, string>([
      ['DEPUTATIE', 'Deputatie'],
      ['AGENTSCHAP_VOOR_NATUUR_EN_BOS', 'Agentschap voor Natuur en Bos'],
      ['COLLEGE_VAN_BURGEMEESTER_EN_SCHEPENEN', 'College van Burgemeester en Schepenen'],
      ['OVAM', 'OVAM'],
      ['PROVINCIE', 'Provincie'],
      ['VLAAMSE_REGERING', 'Vlaamse Regering'],
      ['VLAAMSE_OVERHEID', 'Vlaamse Regering'],
      ['VLAAMS_MINISTER_VAN_LEEFMILIEU', 'Vlaams Minister van Leefmilieu'],
      ['RAAD_VAN_STATE', 'Raad van State'],
      ['RAAD_VOOR_VERGUNNINGSBETWISTING', 'Raad voor Vergunningsbetwisting'],
      ['VLAAMS_GEWEST', 'Vlaams Gewest'],
    ])],
    ['risicoklasse', new Map<string, string>([
      ['KLASSE_1', 'Klasse 1'],
      ['KLASSE_2', 'Klasse 2'],
      ['KLASSE_3', 'Klasse 3'],
    ])],
    ['risicoklasseInput', new Map<string, string>([
      ['KLASSE_1', '1'],
      ['KLASSE_2', '2'],
      ['KLASSE_3', '3'],
    ])],
    ['activiteitType', new Map<string, string>([
      ['', ''],
      ['VLAREBO_2008', 'Vlarebo 2008'],
      ['VLAREM', 'Vlarem'],
    ])],
    ['toegepasteWetgeving', new Map<string, string>([
      ['ARAB', 'ARAB'],
      ['VLAREM', 'Vlarem'],
    ])],
    ['status', new Map<string, string>([
      ['VERVALLEN', 'Vervallen'],
      ['NIET_VERVALLEN', 'Niet vervallen'],
      ['NIET_ONDERZOCHT', 'Niet onderzocht'],
      ['INGETROKKEN_DOOR_AANVRAGER', 'Ingetrokken door aanvrager'],
      ['VERGUNNING_OVERGELATEN', 'Vergunning overgelaten'],
      ['DEELS_VERVALLEN', 'Deels vervallen'],
      ['GEEN_BESLISSING', 'Geen beslissing'],
      ['STOPGEZET', 'Stopgezet'],
      ['INGETROKKEN', 'Ingetrokken'],
      ['VOORWAARDELIJK_VERGUND', 'Voorwaardelijk vergund'],
      ['AKTENAME', 'Aktename'],
      ['GEEN_AKTENAME', 'Geen aktename'],
      ['GEDEELTELIJK_VERGUND', 'Gedeeltelijk vergund'],
      ['TIJDELIJK_VERGUND', 'Tijdelijk vergund'],
      ['VERGUND', 'Vergund'],
      ['GEDEELTELIJK_VOORWAARDELIJK_VERGUND', 'Gedeeltelijk voorwaardelijk vergund'],
      ['ONGEGROND_NIETS_RECHTSGELDIG', 'Ongegrond, niets rechtsgeldig'],
      ['GEWEIGERD', 'Geweigerd'],
      ['GESCHORST', 'Beslissing geschorst'],
      ['DEELS_VERGUND', 'Deels vergund'],
      ['ONVOLLEDIG_ONONTVANKELIJK', 'Onvolledig en onontvankelijk'],
      ['STILZWIJGENDE_AKTENAME', 'Stilzwijgende Aktename'],
      ['STILZWIJGENDE_BEVESTIGING_1STE_AANLEG', 'Stilzwijgende bevestiging 1ste aanleg'],
      ['STILZWIJGEND_GEWEIGERD', 'Stilzwijgend geweigerd'],
      ['GEEN_BESLISSING_NA_RAPPEL', 'Geen beslissing na rappel'],
      ['ZONDER_VOORWERP', 'Zonder voorwerp'],
      ['BIJSTELLING', 'Bijstelling van een bestaande verkaveling'],
      ['NIEUW', 'Aanvraag nieuwe verkaveling'],
      ['NIET_VERNIETIGD', 'Niet vernietigd'],
      ['VERNIETIGD', 'Vernietigd'],
      ['NIET_GESCHORST', 'Niet Geschorst'],
      ['ONVOLLEDIG_ONONTVANKELIJK', 'Onvolledig en onontvankelijk'],
    ])],
    ['vlareboCode', new Map<string, string>([
      ['CATEGORIE_A', 'Vlarebo Categorie A'],
      ['CATEGORIE_A_I', 'Vlarebo Categorie A Inventarisatieplicht'],
      ['CATEGORIE_A_S', 'Vlarebo Categorie A Situatierapport'],
      ['CATEGORIE_A_ASTERISK', 'Vlarebo Categorie A*'],
      ['CATEGORIE_A_ASTERISK_I', 'Vlarebo Categorie A* Inventarisatieplicht'],
      ['CATEGORIE_A_ASTERISK_S', 'Vlarebo Categorie A* Situatierapport'],
      ['CATEGORIE_B', 'Vlarebo Categorie B'],
      ['CATEGORIE_B_I', 'Vlarebo Categorie B Inventarisatieplicht'],
      ['CATEGORIE_B_S', 'Vlarebo Categorie B Situatierapport'],
      ['CATEGORIE_B_ASTERISK', 'Vlarebo Categorie B*'],
      ['CATEGORIE_B_ASTERISK_I', 'Vlarebo Categorie B* Inventarisatieplicht'],
      ['CATEGORIE_B_ASTERISK_S', 'Vlarebo Categorie B* Situatierapport'],
      ['CATEGORIE_C', 'Vlarebo Categorie C'],
      ['CATEGORIE_I', 'Vlarebo Categorie I'],
      ['CATEGORIE_O', 'Vlarebo Categorie O'],
      ['CATEGORIE_O_I', 'Vlarebo Categorie O Inventarisatieplicht'],
      ['CATEGORIE_O_S', 'Vlarebo Categorie O Situatierapport'],
      ['CATEGORIE_S', 'Vlarebo Categorie S'],
      ['NIET_GEKEND', 'Niet gekend'],
      ['NIET_GEVONDEN', 'Niet gevonden'],
    ])],
    ['vlareboCodeInput', new Map<string, string>([
      ['', ''],
      ['CATEGORIE_A', 'A'],
      ['CATEGORIE_A_I', 'A I'],
      ['CATEGORIE_A_S', 'A S'],
      ['CATEGORIE_A_ASTERISK', 'A*'],
      ['CATEGORIE_A_ASTERISK_I', 'A* I'],
      ['CATEGORIE_A_ASTERISK_S', 'A* S'],
      ['CATEGORIE_B', 'B'],
      ['CATEGORIE_B_I', 'B I'],
      ['CATEGORIE_B_S', 'B S'],
      ['CATEGORIE_B_ASTERISK_I', 'B* I'],
      ['CATEGORIE_B_ASTERISK', 'B*'],
      ['CATEGORIE_B_ASTERISK_S', 'B* S'],
      ['CATEGORIE_C', 'C'],
      ['CATEGORIE_I', 'I'],
      ['CATEGORIE_O', 'O'],
      ['CATEGORIE_O_I', 'O I'],
      ['CATEGORIE_O_S', 'O S'],
      ['CATEGORIE_S', 'S'],
      ['NIET_GEKEND', 'Niet gekend'],
      ['NIET_GEVONDEN', 'Niet gevonden'],
    ])],
    ['vlareboCodeDescription', new Map<string, string>([
      ['CATEGORIE_A', 'Oriënterend onderzoek verplicht bij overdracht, sluiting en faillissement, en om de twintig jaar'],
      ['CATEGORIE_A_I', 'Oriënterend onderzoek verplicht bij overdracht, sluiting en faillissement, en om de twintig jaar (Inventarisatieplicht)'],
      ['CATEGORIE_A_S', 'Oriënterend onderzoek verplicht bij overdracht, sluiting en faillissement, en om de twintig jaar (Situatierapport)'],
      ['CATEGORIE_A_ASTERISK', 'Inrichting waarvoor: a) bij uitsluitend bovengrondse opslag conform het Bodemdecreet van 27 oktober 2006 en het VLAREBO van 14 december 2007 een oriënterend bodemonderzoek verplicht is bij overdracht, onteigening, sluiting en faillissement; b) bij ondergrondse opslag of bij combinatie van ondergrondse of bovengrondse opslag conform het Bodemdecreet van 27 oktober 2006 en het VLAREBO van 14 december 2007 een oriënterend onderzoek verplicht is bij overdracht, sluiting en faillissement en om de twintig jaar'],
      ['CATEGORIE_A_ASTERISK_I', 'Inrichting waarvoor: a) bij uitsluitend bovengrondse opslag conform het Bodemdecreet van 27 oktober 2006 en het VLAREBO van 14 december 2007 een oriënterend bodemonderzoek verplicht is bij overdracht, onteigening, sluiting en faillissement; b) bij ondergrondse opslag of bij combinatie van ondergrondse of bovengrondse opslag conform het Bodemdecreet van 27 oktober 2006 en het VLAREBO van 14 december 2007 een oriënterend onderzoek verplicht is bij overdracht, sluiting en faillissement en om de twintig jaar (Inventarisatieplicht)'],
      ['CATEGORIE_A_ASTERISK_S', 'Inrichting waarvoor: a) bij uitsluitend bovengrondse opslag conform het Bodemdecreet van 27 oktober 2006 en het VLAREBO van 14 december 2007 een oriënterend bodemonderzoek verplicht is bij overdracht, onteigening, sluiting en faillissement; b) bij ondergrondse opslag of bij combinatie van ondergrondse of bovengrondse opslag conform het Bodemdecreet van 27 oktober 2006 en het VLAREBO van 14 december 2007 een oriënterend onderzoek verplicht is bij overdracht, sluiting en faillissement en om de twintig jaar (Situatierapport )'],
      ['CATEGORIE_B', 'Oriënterend onderzoek verplicht bij overdracht, sluiting en faillissement, en om de tien jaar'],
      ['CATEGORIE_B_ASTERISK', 'Inrichting waarvoor: a) bij uitsluitend bovengrondse opslag conform het Bodemdecreet van 27 oktober 2006 en het VLAREBO van 14 december 2007 een oriënterend onderzoek verplicht is bij overdracht, sluiting en faillissement en om de twintig jaar; b) bij ondergrondse opslag of bij combinatie van ondergrondse of bovengrondse opslag conform het Bodemdecreet van 27 oktober 2006 en het VLAREBO van 14 december 2007 een oriënterend onderzoek verplicht is bij overdracht, sluiting en faillissement en om de tien jaar'],
      ['CATEGORIE_B_ASTERISK_I', 'Inrichting waarvoor: a) bij uitsluitend bovengrondse opslag conform het Bodemdecreet van 27 oktober 2006 en het VLAREBO van 14 december 2007 een oriënterend onderzoek verplicht is bij overdracht, sluiting en faillissement en om de twintig jaar; b) bij ondergrondse opslag of bij combinatie van ondergrondse of bovengrondse opslag conform het Bodemdecreet van 27 oktober 2006 en het VLAREBO van 14 december 2007 een oriënterend onderzoek verplicht is bij overdracht, sluiting en faillissement en om de tien jaar (Inventarisatieplicht)'],
      ['CATEGORIE_B_ASTERISK_S', 'Inrichting waarvoor: a) bij uitsluitend bovengrondse opslag conform het Bodemdecreet van 27 oktober 2006 en het VLAREBO van 14 december 2007 een oriënterend onderzoek verplicht is bij overdracht, sluiting en faillissement en om de twintig jaar; b) bij ondergrondse opslag of bij combinatie van ondergrondse of bovengrondse opslag conform het Bodemdecreet van 27 oktober 2006 en het VLAREBO van 14 december 2007 een oriënterend onderzoek verplicht is bij overdracht, sluiting en faillissement en om de tien jaar (Situatierapport)'],
      ['CATEGORIE_B_I', 'Oriënterend onderzoek verplicht bij overdracht, sluiting en faillissement, en om de tien jaar (Inventarisatieplicht)'],
      ['CATEGORIE_B_S', 'Oriënterend onderzoek verplicht bij overdracht, sluiting en faillissement, en om de tien jaar (Situatierapport)'],
      ['CATEGORIE_C', 'Oriënterend onderzoek verplicht bij overdracht, sluiting en faillissement, en om de vijf jaar'],
      ['CATEGORIE_O', 'Oriënterend onderzoek verplicht bij overdracht, sluiting en faillissement'],
      ['CATEGORIE_S', 'De exploitant dient onder leiding van een bodemsaneringsdeskundige een oriënterend bodemonderzoek uit te laten voeren en het verslag ervan aan de OVAM te bezorgen op verschillende tijdstippen, zie Vlarem I bijlage 1'],
      ['CATEGORIE_O_I', 'Oriënterend onderzoek verplicht bij overdracht, sluiting en faillissement (Inventarisatieplicht)'],
      ['CATEGORIE_O_S', 'Oriënterend onderzoek verplicht bij overdracht, sluiting en faillissement (Situatierapport)'],
      ['NIET_GEKEND', 'Niet gekend'],
      ['NIET_GEVONDEN', 'Niet gevonden'],
      ['CATEGORIE_I', 'Vlarebo Categorie I'],
    ])]

  ],
  )

  const getLabel = (optionKey: string, valueKey: string): string => {
    const list = options.get(optionKey)
    if (!list) {
      return 'Option not found: ' + optionKey + ' - ' + valueKey
    }
    if (list.has(valueKey)) {
      return list.get(valueKey)
    } else {
      return valueKey
    }
  }
  const toelichting = 'Mogelijks worden niet alle activiteiten vermeld die opgenomen zijn in de milieuvergunning. De aanvrager neemt best contact op met de stad of gemeente om deze informatie te verkrijgen.'

  return { options, getLabel, toelichting }
}
