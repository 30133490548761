import { formatDate } from '@/infrastructure/filters/filters'

export default function useVerkavelingsvergunningVervalLabels () {
  const vervalOptions =
      new Map<string, Map<string, string>>([
        ['vervalStatus', new Map<string, string>([
          ['NIET_ONDERZOCHT', 'Verval van de vergunning zal onderzocht worden als hierom verzocht wordt'],
          ['VERVALLEN', 'Vervallen'],
          ['NIET_VERVALLEN', 'Niet vervallen'],
          ['INGETROKKEN', 'Ingetrokken'],
          ['DEELS_VERVALLEN', 'Deels vervallen'],
          ['HERZIEN_BPA_RUP', 'Herzien door BPA of RUP'],
          ['HERZIEN_COLLEGE', 'Herzien door college'],
          ['OPGEHEVEN_BPA_RUP', 'Opgeheven door BPA of RUP'],
          ['OPGEHEVEN_COLLEGE', 'Opgeheven door college'],
          ['VERZAAKT', 'Verzaakt']
        ])],
        ['vervalStatusToekomst', new Map<string, string>([
          ['VERVALLEN', 'Vervalt'],
          ['NIET_VERVALLEN', 'Niet vervallen'],
          ['NIET_ONDERZOCHT', 'Verval van de vergunning zal onderzocht worden als hierom verzocht wordt'],
          ['INGETROKKEN', 'Ingetrokken'],
          ['DEELS_VERVALLEN', 'Vervalt deels'],
          ['HERZIEN_BPA_RUP', 'Wordt herzien door BPA of RUP'],
          ['HERZIEN_COLLEGE', 'Herzien door college'],
          ['OPGEHEVEN_BPA_RUP', 'Wordt opgeheven door BPA of RUP'],
          ['OPGEHEVEN_COLLEGE', 'Wordt opgeheven door college'],
          ['VERZAAKT', 'Wordt verzaakt']
        ])],
        ['lotInfoStatus', new Map<string, string>([
          ['NIET_ONDERZOCHT', 'Niet onderzocht'],
          ['NIET_VERVALLEN', 'Niet vervallen'],
          ['VERVALLEN', 'Vervallen'],
        ])],
        ['lotInfoStatusToekomst', new Map<string, string>([
          ['NIET_ONDERZOCHT', 'Niet onderzocht'],
          ['NIET_VERVALLEN', 'Niet vervallen'],
          ['VERVALLEN', 'Vervalt op']
        ])]
      ])

  const vervalTekst = (status: string, datum?: Date, lotInfo?: boolean): string => {
    let previewText = ''
    const vervalDate = new Date(datum ?? new Date())
    const today = new Date()
    // Set the time part to 00:00:00 to ignore the time
    vervalDate.setHours(0, 0, 0, 0)
    today.setHours(0, 0, 0, 0)
    if (vervalDate > today) {
      previewText = vervalOptions.get(lotInfo ? 'lotInfoStatusToekomst' : 'vervalStatusToekomst').get(status)
    } else {
      previewText = vervalOptions.get(lotInfo ? 'lotInfoStatus' : 'vervalStatus').get(status)
    }
    if (datum) {
      previewText += ` op ${formatDate(datum)}`
    }
    return previewText
  }

  return { vervalOptions, vervalTekst }
}
