import {
  MilieuvergunningInlichting,
  VergunningActiviteit,
  VergunningActiviteitStatus
} from '@/infrastructure/bff-client/bff-client'
import { formatDate, formatNumber } from '@/infrastructure/filters/filters'
import { defineComponent, PropType, readonly } from 'vue'
import useInlichtingPreview from '@/components/vip-inlichting/new-version/composables/inlichting-preview.composable'
import useMilieuVergunningLabels
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/milieuvergunning/composables/milieuvergunning-labels'

export default defineComponent({
  name: 'milieuvergunning-preview',
  components: {
  },
  props: {
    inlichting: Object as PropType<MilieuvergunningInlichting>
  },
  setup (props) {
    const { options, getLabel, toelichting } = useMilieuVergunningLabels()
    const { downloadUrl } = useInlichtingPreview()
    const inlichtingTitle = 'Milieuvergunning'
    const milieuvergunningInlichting = readonly(props.inlichting as MilieuvergunningInlichting)
    const beroepsprocedures = milieuvergunningInlichting.beroepsprocedures as any[]
    const checkOrCross = (activiteitStatus: VergunningActiviteitStatus): string => {
      return activiteitStatus === VergunningActiviteitStatus.VERGUND ||
      activiteitStatus === VergunningActiviteitStatus.DEELS_VERGUND ||
      activiteitStatus === VergunningActiviteitStatus.AKTENAME
        ? 'check'
        : 'cross'
    }
    const getGeldigPeriodeTekst = (geldigVan: string, geldigTotEnMet: string, totEnMet: boolean = false) => {
      let geldigVanTekst = ''
      if (geldigVan) {
        geldigVanTekst = ' van ' + geldigVan
      }
      let geldigTotEnMetTekst = ''
      if (geldigTotEnMet) {
        geldigTotEnMetTekst = `${totEnMet ? ' t.e.m. ' : ' tot '}` + geldigTotEnMet
      }
      return 'Geldig' + geldigVanTekst + geldigTotEnMetTekst
    }

    const getActiviteitTekstFormatted = (activiteit: VergunningActiviteit) => {
      let omschrijving = ''
      if (activiteit.type) {
        omschrijving += getLabel('activiteitType', activiteit.type) + '$'
      }
      if (activiteit.vlareboCode) {
        omschrijving += '<abbr title="' + getLabel('vlareboCodeDescription', activiteit.vlareboCode) + '">' + getLabel('vlareboCode', activiteit.vlareboCode) + '</abbr>'
      }
      if (activiteit.risicoklasse) {
        omschrijving += '$' + getLabel('risicoklasse', activiteit.risicoklasse)
      }
      if (activiteit.status) {
        omschrijving += '$' + getLabel('status', activiteit.status)
      }
      if (activiteit.startdatum) {
        omschrijving += '$' + getGeldigPeriodeTekst(formatDate(activiteit.startdatum), formatDate(activiteit.einddatum), true)
      }
      return omschrijving.replaceAll('$', ' - ')
    }

    return {
      beroepsprocedures,
      milieuvergunningInlichting,
      toelichting,
      options,
      inlichtingTitle,
      checkOrCross,
      getGeldigPeriodeTekst,
      getActiviteitTekstFormatted,
      getLabel,
      downloadUrl,
      formatDate,
      formatNumber,
    }
  }
})
