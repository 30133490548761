import { defineComponent, PropType, readonly } from 'vue'
import useOmgevingVergunningLabels from '../../composables/omgevingsvergunning-labels'
import { formatDate } from '@/infrastructure/filters/filters'
import { Kleinhandelsactiviteit } from '@/infrastructure/bff-client/bff-client'

export default defineComponent({
  name: 'kleinhandels-activiteiten-preview',
  props: {
    previewData: Object as PropType<Kleinhandelsactiviteit>
  },
  setup (props) {
    const { options, getLabel, vervalTekst } = useOmgevingVergunningLabels()
    const preview = readonly(props.previewData as Kleinhandelsactiviteit)

    return { options, preview, getLabel, formatDate, vervalTekst }
  }
})
