import { GuiInlichtingRubriek, TypeInlichting, VipInlichtingType } from '@/infrastructure/bff-client/bff-client'
import { groupBy } from '@/infrastructure/helpers/inlichting-map'

export enum Hoedanigheid {
  READ_ONLY = 'READ_ONLY',
  ADAPT = 'ADAPT',
  FEED = 'FEED',
}

export interface InlichtingInfo {
  inlichtingType: TypeInlichting,
  inlichtingTypeApi: string,
  inlichtingVersion: number,
  label: string,
  hoedanigheid: Hoedanigheid,
  rubriek: GuiInlichtingRubriek,
  mandatory?: boolean,
  group?: string,
  template?: string
}

export const inlichtingen: InlichtingInfo[] = [
  { inlichtingType: TypeInlichting.GewestplanV1, inlichtingTypeApi: VipInlichtingType.GEWESTPLAN, inlichtingVersion: 1, label: 'Gewestplan', hoedanigheid: Hoedanigheid.ADAPT, rubriek: GuiInlichtingRubriek.Overheidsplannen, mandatory: true, template: 'gewestplan-v1' },
  { inlichtingType: TypeInlichting.GewestplanV2, inlichtingTypeApi: VipInlichtingType.GEWESTPLAN, inlichtingVersion: 2, label: 'Gewestplan', hoedanigheid: Hoedanigheid.ADAPT, rubriek: GuiInlichtingRubriek.Overheidsplannen, mandatory: false, template: 'gewestplan-v2' },
  { inlichtingType: TypeInlichting.AlgemeenPlanVanAanleg, inlichtingTypeApi: VipInlichtingType.ALGEMEEN_PLAN_VAN_AANLEG, inlichtingVersion: 1, label: 'Algemeen plan van aanleg', hoedanigheid: Hoedanigheid.ADAPT, rubriek: GuiInlichtingRubriek.Overheidsplannen, mandatory: true, template: 'algemeen-plan-van-aanleg' },
  { inlichtingType: TypeInlichting.BijzonderPlanVanAanleg, inlichtingTypeApi: VipInlichtingType.BIJZONDER_PLAN_VAN_AANLEG, inlichtingVersion: 1, label: 'Bijzonder plan van aanleg', hoedanigheid: Hoedanigheid.ADAPT, rubriek: GuiInlichtingRubriek.Overheidsplannen, mandatory: true, template: 'bijzonder-plan-van-aanleg' },
  { inlichtingType: TypeInlichting.GewestelijkRuimtelijkUitvoeringsplan, inlichtingTypeApi: VipInlichtingType.GEWESTELIJK_RUIMTELIJK_UITVOERINGSPLAN, inlichtingVersion: 1, label: 'Gewestelijk', hoedanigheid: Hoedanigheid.ADAPT, rubriek: GuiInlichtingRubriek.Overheidsplannen, mandatory: true, group: 'Ruimtelijk uitvoeringsplan', template: 'gewestelijk-ruimtelijk-uitvoeringsplan' },
  { inlichtingType: TypeInlichting.ProvinciaalRuimtelijkUitvoeringsplan, inlichtingTypeApi: VipInlichtingType.PROVINCIAAL_RUIMTELIJK_UITVOERINGSPLAN, inlichtingVersion: 1, label: 'Provinciaal', hoedanigheid: Hoedanigheid.ADAPT, rubriek: GuiInlichtingRubriek.Overheidsplannen, mandatory: true, group: 'Ruimtelijk uitvoeringsplan', template: 'provinciaal-ruimtelijk-uitvoeringsplan' },
  { inlichtingType: TypeInlichting.GemeentelijkRuimtelijkUitvoeringsplan, inlichtingTypeApi: VipInlichtingType.GEMEENTELIJK_RUIMTELIJK_UITVOERINGSPLAN, inlichtingVersion: 1, label: 'Gemeentelijk', hoedanigheid: Hoedanigheid.ADAPT, rubriek: GuiInlichtingRubriek.Overheidsplannen, mandatory: true, group: 'Ruimtelijk uitvoeringsplan', template: 'gemeentelijk-ruimtelijk-uitvoeringsplan' },
  { inlichtingType: TypeInlichting.GewestelijkeVerordening, inlichtingTypeApi: VipInlichtingType.GEWESTELIJKE_VERORDENING, inlichtingVersion: 1, label: 'Gewestelijk', hoedanigheid: Hoedanigheid.ADAPT, rubriek: GuiInlichtingRubriek.Overheidsplannen, mandatory: true, group: 'Verordening', template: 'gewestelijke-verordening' },
  { inlichtingType: TypeInlichting.ProvincialeVerordening, inlichtingTypeApi: VipInlichtingType.PROVINCIALE_VERORDENING, inlichtingVersion: 1, label: 'Provinciaal', hoedanigheid: Hoedanigheid.ADAPT, rubriek: GuiInlichtingRubriek.Overheidsplannen, mandatory: true, group: 'Verordening', template: 'provinciale-verordening' },
  { inlichtingType: TypeInlichting.GemeentelijkeVerordening, inlichtingTypeApi: VipInlichtingType.GEMEENTELIJKE_VERORDENING, inlichtingVersion: 1, label: 'Gemeentelijk', hoedanigheid: Hoedanigheid.ADAPT, rubriek: GuiInlichtingRubriek.Overheidsplannen, mandatory: true, group: 'Verordening', template: 'gemeentelijke-verordening' },
  { inlichtingType: TypeInlichting.GemeentelijkRooilijnplan, inlichtingTypeApi: VipInlichtingType.GEMEENTELIJK_ROOILIJNPLAN, inlichtingVersion: 1, label: 'Gemeentelijk rooilijnplan', hoedanigheid: Hoedanigheid.ADAPT, rubriek: GuiInlichtingRubriek.Overheidsplannen, mandatory: true, template: 'rooilijnplan' },
  { inlichtingType: TypeInlichting.GemeentelijkOnteigeningsplan, inlichtingTypeApi: VipInlichtingType.GEMEENTELIJK_ONTEIGENINGSPLAN, inlichtingVersion: 1, label: 'Gemeentelijk onteigeningsplan', hoedanigheid: Hoedanigheid.ADAPT, rubriek: GuiInlichtingRubriek.Overheidsplannen, mandatory: true, template: 'onteigeningsplan' },
  // { inlichtingType: TypeInlichting.RechtVanVoorkoop, inlichtingTypeApi: VipInlichtingType.RECHT_VAN_VOORKOOP, inlichtingVersion: 1, label: 'Recht van voorkoop', hoedanigheid: Hoedanigheid.READ_ONLY, rubriek: GuiInlichtingRubriek.Overheidsplannen }, //TODO nog toe te voegen/mappen
  { inlichtingType: TypeInlichting.RuilOfHerverkavelingsplan, inlichtingTypeApi: VipInlichtingType.RUIL_OF_HERVERKAVELINGSPLAN, inlichtingVersion: 1, label: 'Ruil- of herverkavelingsplan', hoedanigheid: Hoedanigheid.ADAPT, rubriek: GuiInlichtingRubriek.Overheidsplannen, template: 'ruil-of-herverkavelingsplan' },
  { inlichtingType: TypeInlichting.HerzieningOfOpheffingVerouderdeInrichtingsvoorschriftenV1, inlichtingTypeApi: VipInlichtingType.HERZIENING_OF_OPHEFFING_VEROUDERDE_INRICHTINGSVOORSCHRIFTEN, inlichtingVersion: 1, label: 'Herziening of opheffing verouderde inrichtingsvoorschriften', hoedanigheid: Hoedanigheid.ADAPT, rubriek: GuiInlichtingRubriek.Overheidsplannen, mandatory: true, template: 'herziening-of-opheffing-verouderde-inrichtingsvoorschriften' },
  { inlichtingType: TypeInlichting.WatergevoeligOpenruimtegebiedV1, inlichtingTypeApi: VipInlichtingType.WATERGEVOELIG_OPENRUIMTEGEBIED, inlichtingVersion: 1, label: 'Watergevoelig openruimtegebied', hoedanigheid: Hoedanigheid.ADAPT, rubriek: GuiInlichtingRubriek.Overheidsplannen, mandatory: true, template: 'watergevoelig-openruimtegebied' },
  { inlichtingType: TypeInlichting.ComplexProject, inlichtingTypeApi: VipInlichtingType.COMPLEX_PROJECT, inlichtingVersion: 1, label: 'Complex project', hoedanigheid: Hoedanigheid.ADAPT, rubriek: GuiInlichtingRubriek.Overheidsplannen, template: 'complex-project' },
  { inlichtingType: TypeInlichting.PlanbatenOfSchade, inlichtingTypeApi: VipInlichtingType.PLANBATEN_OF_SCHADE, inlichtingVersion: 1, label: 'Planbaten of -schade', hoedanigheid: Hoedanigheid.ADAPT, rubriek: GuiInlichtingRubriek.Overheidsplannen, template: 'planbaten-of-schade' },
  { inlichtingType: TypeInlichting.Beleidsplan, inlichtingTypeApi: VipInlichtingType.BELEIDSPLAN, inlichtingVersion: 1, label: 'Beleidsplan', hoedanigheid: Hoedanigheid.ADAPT, rubriek: GuiInlichtingRubriek.Overheidsplannen, template: 'beleidsplan' },
  { inlichtingType: TypeInlichting.SoortWegWaarlangsPerceelGelegenIs, inlichtingTypeApi: VipInlichtingType.SOORT_WEG_WAARLANGS_PERCEEL_GELEGEN_IS, inlichtingVersion: 1, label: 'Soort weg waarlangs het perceel is gelegen', hoedanigheid: Hoedanigheid.READ_ONLY, rubriek: GuiInlichtingRubriek.Overheidsplannen, template: 'soort-weg-v1' },
  { inlichtingType: TypeInlichting.SoortWegWaarlangsPerceelGelegenIsV2, inlichtingTypeApi: VipInlichtingType.SOORT_WEG_WAARLANGS_PERCEEL_GELEGEN_IS, inlichtingVersion: 2, label: 'Soort weg waarlangs het perceel is gelegen', hoedanigheid: Hoedanigheid.ADAPT, rubriek: GuiInlichtingRubriek.Overheidsplannen, template: 'soort-weg-v2' },
  { inlichtingType: TypeInlichting.VoorwaardenVanuitEenGemeentelijkeAkte, inlichtingTypeApi: VipInlichtingType.VOORWAARDEN_VANUIT_EEN_GEMEENTELIJKE_AKTE, inlichtingVersion: 1, label: 'Voorwaarden vanuit een gemeentelijke akte', hoedanigheid: Hoedanigheid.ADAPT, rubriek: GuiInlichtingRubriek.Overheidsplannen, template: 'voorwaarden-vanuit-gemeentelijke-akte' },
  { inlichtingType: TypeInlichting.AsBuiltAttest, inlichtingTypeApi: VipInlichtingType.AS_BUILT_ATTEST, inlichtingVersion: 1, label: 'As-built attest', hoedanigheid: Hoedanigheid.FEED, rubriek: GuiInlichtingRubriek.Vergunningen, template: 'as-built-attest' },
  { inlichtingType: TypeInlichting.Milieuvergunning, inlichtingTypeApi: VipInlichtingType.MILIEUVERGUNNING, inlichtingVersion: 1, label: 'Milieuvergunning', hoedanigheid: Hoedanigheid.FEED, rubriek: GuiInlichtingRubriek.Vergunningen, mandatory: true, template: 'milieuvergunning' },
  { inlichtingType: TypeInlichting.Omgevingsvergunning, inlichtingTypeApi: VipInlichtingType.OMGEVINGSVERGUNNING, inlichtingVersion: 1, label: 'Omgevingsvergunning', hoedanigheid: Hoedanigheid.FEED, rubriek: GuiInlichtingRubriek.Vergunningen, mandatory: true, template: 'omgevingsvergunning' },
  { inlichtingType: TypeInlichting.PlanologischAttest, inlichtingTypeApi: VipInlichtingType.PLANOLOGISCH_ATTEST, inlichtingVersion: 1, label: 'Planologisch attest', hoedanigheid: Hoedanigheid.FEED, rubriek: GuiInlichtingRubriek.Vergunningen, template: 'planologisch-attest' },
  { inlichtingType: TypeInlichting.Splitsing, inlichtingTypeApi: VipInlichtingType.SPLITSING, inlichtingVersion: 1, label: 'Splitsing', hoedanigheid: Hoedanigheid.FEED, rubriek: GuiInlichtingRubriek.Vergunningen, template: 'splitsing' },
  { inlichtingType: TypeInlichting.StedenbouwkundigAttest, inlichtingTypeApi: VipInlichtingType.STEDENBOUWKUNDIG_ATTEST, inlichtingVersion: 1, label: 'Stedenbouwkundig attest', hoedanigheid: Hoedanigheid.FEED, rubriek: GuiInlichtingRubriek.Vergunningen, mandatory: true, template: 'stedenbouwkundig-attest' },
  { inlichtingType: TypeInlichting.StedenbouwkundigeMelding, inlichtingTypeApi: VipInlichtingType.STEDENBOUWKUNDIGE_MELDING, inlichtingVersion: 1, label: 'Stedenbouwkundige melding', hoedanigheid: Hoedanigheid.FEED, rubriek: GuiInlichtingRubriek.Vergunningen, mandatory: true, template: 'stedenbouwkundige-melding' },
  { inlichtingType: TypeInlichting.StedenbouwkundigeOvertreding, inlichtingTypeApi: VipInlichtingType.STEDENBOUWKUNDIGE_OVERTREDING, inlichtingVersion: 1, label: 'Stedenbouwkundige overtreding', hoedanigheid: Hoedanigheid.FEED, rubriek: GuiInlichtingRubriek.Vergunningen, mandatory: true, template: 'stedenbouwkundige-overtreding' },
  { inlichtingType: TypeInlichting.StedenbouwkundigeVergunning, inlichtingTypeApi: VipInlichtingType.STEDENBOUWKUNDIGE_VERGUNNING, inlichtingVersion: 1, label: 'Stedenbouwkundige vergunning', hoedanigheid: Hoedanigheid.FEED, rubriek: GuiInlichtingRubriek.Vergunningen, mandatory: true, template: 'stedenbouwkundige-vergunning' },
  { inlichtingType: TypeInlichting.Verkavelingsvergunning, inlichtingTypeApi: VipInlichtingType.VERKAVELINGSVERGUNNING, inlichtingVersion: 1, label: 'Verkavelingsvergunning', hoedanigheid: Hoedanigheid.FEED, rubriek: GuiInlichtingRubriek.Vergunningen, mandatory: true, template: 'verkavelingsvergunning' },
  { inlichtingType: TypeInlichting.VergundGeachteGebouwen, inlichtingTypeApi: VipInlichtingType.VERGUND_GEACHTE_GEBOUWEN, inlichtingVersion: 1, label: 'Vergund geacht gebouw', hoedanigheid: Hoedanigheid.FEED, rubriek: GuiInlichtingRubriek.Vergunningen, template: 'vergund-geachte-gebouwen' },
  { inlichtingType: TypeInlichting.Vergunningstoestand, inlichtingTypeApi: VipInlichtingType.VERGUNNINGSTOESTAND, inlichtingVersion: 1, label: 'Vergunningstoestand', hoedanigheid: Hoedanigheid.FEED, rubriek: GuiInlichtingRubriek.Vergunningen, template: 'vergunningstoestand' },
  { inlichtingType: TypeInlichting.BeschermdDuingebied, inlichtingTypeApi: VipInlichtingType.BESCHERMD_DUINGEBIED, inlichtingVersion: 1, label: 'Beschermd duingebied', hoedanigheid: Hoedanigheid.READ_ONLY, rubriek: GuiInlichtingRubriek.Natuur, template: 'beschermd-duingebied' },
  { inlichtingType: TypeInlichting.BeschermdWaterwinningsgebied, inlichtingTypeApi: VipInlichtingType.BESCHERMD_WATERWINNINGSGEBIED, inlichtingVersion: 1, label: 'Beschermd waterwinningsgebied', hoedanigheid: Hoedanigheid.READ_ONLY, rubriek: GuiInlichtingRubriek.Natuur, template: 'beschermd-waterwinningsgebied' },
  { inlichtingType: TypeInlichting.Habitatrichtlijngebied, inlichtingTypeApi: VipInlichtingType.HABITATRICHTLIJNGEBIED, inlichtingVersion: 1, label: 'Habitatrichtlijngebied', hoedanigheid: Hoedanigheid.READ_ONLY, rubriek: GuiInlichtingRubriek.Natuur, template: 'habitatrichtlijngebied' },
  { inlichtingType: TypeInlichting.Kapmachtiging, inlichtingTypeApi: VipInlichtingType.KAPMACHTIGING, inlichtingVersion: 1, label: 'Gemeentelijke kapmachtiging', hoedanigheid: Hoedanigheid.FEED, rubriek: GuiInlichtingRubriek.Natuur, template: 'kapmachtiging' },
  { inlichtingType: TypeInlichting.Natuurbeheerplan, inlichtingTypeApi: VipInlichtingType.NATUURBEHEERPLAN, inlichtingVersion: 1, label: 'Beheerplan', hoedanigheid: Hoedanigheid.READ_ONLY, rubriek: GuiInlichtingRubriek.Natuur, template: 'natuurbeheerplan' },
  { inlichtingType: TypeInlichting.RisicozoneVoorOverstromingen, inlichtingTypeApi: VipInlichtingType.RISICOZONE_VOOR_OVERSTROMINGEN, inlichtingVersion: 1, label: 'Risicozone voor overstromingen', hoedanigheid: Hoedanigheid.READ_ONLY, rubriek: GuiInlichtingRubriek.Natuur, mandatory: true, template: 'risicozone-voor-overstromingen' },
  { inlichtingType: TypeInlichting.Signaalgebied, inlichtingTypeApi: VipInlichtingType.SIGNAALGEBIED, inlichtingVersion: 1, label: 'Signaalgebied', hoedanigheid: Hoedanigheid.READ_ONLY, rubriek: GuiInlichtingRubriek.Natuur, template: 'signaalgebied' },
  { inlichtingType: TypeInlichting.OverstromingsgebiedEnOeverzone, inlichtingTypeApi: VipInlichtingType.OVERSTROMINGSGEBIED_EN_OEVERZONE, inlichtingVersion: 1, label: 'Overstromingsgebied of oeverzone', hoedanigheid: Hoedanigheid.READ_ONLY, rubriek: GuiInlichtingRubriek.Natuur, mandatory: true, template: 'overstromingsgebied-en-oeverzone' },
  { inlichtingType: TypeInlichting.OverstromingsgevoeligheidV2, inlichtingTypeApi: VipInlichtingType.OVERSTROMINGSGEVOELIGHEID, inlichtingVersion: 2, label: 'Overstromingsgevoeligheid', hoedanigheid: Hoedanigheid.READ_ONLY, rubriek: GuiInlichtingRubriek.Natuur, mandatory: true, template: 'overstromingsgevoeligheid' },
  { inlichtingType: TypeInlichting.VenEnIvonGebied, inlichtingTypeApi: VipInlichtingType.VEN_EN_IVON_GEBIED, inlichtingVersion: 1, label: 'VEN- & IVON-gebied', hoedanigheid: Hoedanigheid.READ_ONLY, rubriek: GuiInlichtingRubriek.Natuur, template: 'ven-en-ivon-gebied' },
  { inlichtingType: TypeInlichting.Vogelrichtlijngebied, inlichtingTypeApi: VipInlichtingType.VOGELRICHTLIJNGEBIED, inlichtingVersion: 1, label: 'Vogelrichtlijngebied', hoedanigheid: Hoedanigheid.READ_ONLY, rubriek: GuiInlichtingRubriek.Natuur, template: 'vogelrichtlijngebied' },
  { inlichtingType: TypeInlichting.Natuurinrichtingsproject, inlichtingTypeApi: VipInlichtingType.NATUURINRICHTINGSPROJECT, inlichtingVersion: 1, label: 'Natuurinrichtingsproject', hoedanigheid: Hoedanigheid.READ_ONLY, rubriek: GuiInlichtingRubriek.Natuur, template: 'natuurinrichtingsproject' },
  { inlichtingType: TypeInlichting.Grondverschuivingsgevoeligheid, inlichtingTypeApi: VipInlichtingType.GRONDVERSCHUIVINGSGEVOELIGHEID, inlichtingVersion: 1, label: 'Grondverschuivingsgevoeligheid', hoedanigheid: Hoedanigheid.READ_ONLY, rubriek: GuiInlichtingRubriek.Natuur, template: 'grondverschuivingsgevoeligheid' },
  { inlichtingType: TypeInlichting.WettelijkeErfdienstbaarheidVanOpenbaarNut, inlichtingTypeApi: VipInlichtingType.WETTELIJKE_ERFDIENSTBAARHEID_VAN_OPENBAAR_NUT, inlichtingVersion: 1, label: 'Wettelijke erfdienstbaarheid van openbaar nut', hoedanigheid: Hoedanigheid.FEED, rubriek: GuiInlichtingRubriek.Erfdienstbaarheden, template: 'wettelijke-erfdienstbaarheid' },
  { inlichtingType: TypeInlichting.AankoopOfGebruikGemeentegrond, inlichtingTypeApi: VipInlichtingType.AANKOOP_OF_GEBRUIK_GEMEENTEGROND, inlichtingVersion: 1, label: 'Aankoop of gebruik gemeentegrond', hoedanigheid: Hoedanigheid.FEED, rubriek: GuiInlichtingRubriek.GrondEnPandenBeleid, template: 'aankoop-of-gebruik-gemeentegrond' },
  { inlichtingType: TypeInlichting.Bedrijventerrein, inlichtingTypeApi: VipInlichtingType.BEDRIJVENTERREIN, inlichtingVersion: 1, label: 'Bedrijventerrein', hoedanigheid: Hoedanigheid.READ_ONLY, rubriek: GuiInlichtingRubriek.GrondEnPandenBeleid, template: 'bedrijventerrein' },
  { inlichtingType: TypeInlichting.BesluitBurgemeesterInzakeOpenbareVeiligheid, inlichtingTypeApi: VipInlichtingType.BESLUIT_BURGEMEESTER_INZAKE_OPENBARE_VEILIGHEID, inlichtingVersion: 1, label: 'Besluit van de burgemeester inzake openbare veiligheid', hoedanigheid: Hoedanigheid.FEED, rubriek: GuiInlichtingRubriek.GrondEnPandenBeleid, template: 'besluit-burgemeester-inzake-openbare-veiligheid' },
  { inlichtingType: TypeInlichting.Brownfield, inlichtingTypeApi: VipInlichtingType.BROWNFIELD, inlichtingVersion: 1, label: 'Brownfieldconvenant', hoedanigheid: Hoedanigheid.READ_ONLY, rubriek: GuiInlichtingRubriek.GrondEnPandenBeleid, template: 'brownfield' },
  { inlichtingType: TypeInlichting.Conformiteitsattest, inlichtingTypeApi: VipInlichtingType.CONFORMITEITSATTEST, inlichtingVersion: 1, label: 'Conformiteitsattest', hoedanigheid: Hoedanigheid.READ_ONLY, rubriek: GuiInlichtingRubriek.GrondEnPandenBeleid, mandatory: true, template: 'conformiteitsattest' },
  { inlichtingType: TypeInlichting.Herstelvordering, inlichtingTypeApi: VipInlichtingType.HERSTELVORDERING, inlichtingVersion: 1, label: 'Herstelvordering', hoedanigheid: Hoedanigheid.READ_ONLY, rubriek: GuiInlichtingRubriek.GrondEnPandenBeleid, mandatory: true, template: 'herstelvordering' },
  { inlichtingType: TypeInlichting.LeegstaandeEnVerwaarloosdeGebouwen, inlichtingTypeApi: VipInlichtingType.LEEGSTAANDE_EN_VERWAARLOOSDE_GEBOUWEN, inlichtingVersion: 1, label: 'Leegstaand en/of verwaarloosd gebouw', hoedanigheid: Hoedanigheid.FEED, rubriek: GuiInlichtingRubriek.GrondEnPandenBeleid, mandatory: true, template: 'leegstaande-en-verwaarloosde-gebouwen' },
  { inlichtingType: TypeInlichting.LeegstaandeEnVerwaarloosdeBedrijfsruimten, inlichtingTypeApi: VipInlichtingType.LEEGSTAANDE_EN_VERWAARLOOSDE_BEDRIJFSRUIMTEN, inlichtingVersion: 1, label: 'Leegstaande en/of verwaarloosde bedrijfsruimte', hoedanigheid: Hoedanigheid.FEED, rubriek: GuiInlichtingRubriek.GrondEnPandenBeleid, template: 'leegstaande-en-verwaarloosde-bedrijfsruimten' },
  { inlichtingType: TypeInlichting.LeegstaandeEnVerwaarloosdeBedrijfsruimtenV2, inlichtingTypeApi: VipInlichtingType.LEEGSTAANDE_EN_VERWAARLOOSDE_BEDRIJFSRUIMTEN, inlichtingVersion: 2, label: 'Leegstaande en/of verwaarloosde bedrijfsruimte', hoedanigheid: Hoedanigheid.READ_ONLY, rubriek: GuiInlichtingRubriek.GrondEnPandenBeleid, template: 'leegstaande-en-verwaarloosde-bedrijfsruimten-v2' },
  { inlichtingType: TypeInlichting.OnbebouwdePercelen, inlichtingTypeApi: VipInlichtingType.ONBEBOUWDE_PERCELEN, inlichtingVersion: 1, label: 'Onbebouwd perceel', hoedanigheid: Hoedanigheid.FEED, rubriek: GuiInlichtingRubriek.GrondEnPandenBeleid, template: 'onbebouwde-percelen' },
  { inlichtingType: TypeInlichting.OnbewoonbareEnOngeschikteWoning, inlichtingTypeApi: VipInlichtingType.ONBEWOONBARE_EN_ONGESCHIKTE_WONING, inlichtingVersion: 1, label: 'Onbewoonbare en/of ongeschikte woning', hoedanigheid: Hoedanigheid.READ_ONLY, rubriek: GuiInlichtingRubriek.GrondEnPandenBeleid, mandatory: true, template: 'onbewoonbare-en-ongeschikte-woning' },
  { inlichtingType: TypeInlichting.VerbodTotPermanentWonen, inlichtingTypeApi: VipInlichtingType.VERBOD_TOT_PERMANENT_WONEN, inlichtingVersion: 1, label: 'Verbod tot permanent wonen', hoedanigheid: Hoedanigheid.FEED, rubriek: GuiInlichtingRubriek.GrondEnPandenBeleid, template: 'verbod-tot-permanent-wonen' },
  { inlichtingType: TypeInlichting.Risicogrond, inlichtingTypeApi: VipInlichtingType.RISICOGROND, inlichtingVersion: 1, label: 'Risicogrond', hoedanigheid: Hoedanigheid.READ_ONLY, rubriek: GuiInlichtingRubriek.Milieu, mandatory: false, template: 'risicogrond-v1' },
  { inlichtingType: TypeInlichting.RisicogrondV2, inlichtingTypeApi: VipInlichtingType.RISICOGROND, inlichtingVersion: 2, label: 'Risicogrond', hoedanigheid: Hoedanigheid.ADAPT, rubriek: GuiInlichtingRubriek.Milieu, mandatory: true, template: 'risicogrond-v2' },
  { inlichtingType: TypeInlichting.Zoneringsplan, inlichtingTypeApi: VipInlichtingType.ZONERINGSPLAN, inlichtingVersion: 1, label: 'Zoneringsplan', hoedanigheid: Hoedanigheid.READ_ONLY, rubriek: GuiInlichtingRubriek.Milieu, mandatory: false, template: 'zoneringsplan' },
  { inlichtingType: TypeInlichting.GemeentelijkeHeffing, inlichtingTypeApi: VipInlichtingType.GEMEENTELIJKE_HEFFING, inlichtingVersion: 1, label: 'Gemeentelijke heffing', hoedanigheid: Hoedanigheid.FEED, rubriek: GuiInlichtingRubriek.GemeentelijkeHeffingen, template: 'gemeentelijke-heffing' },
  { inlichtingType: TypeInlichting.OnroerendErfgoed, inlichtingTypeApi: VipInlichtingType.ONROEREND_ERFGOED, inlichtingVersion: 1, label: 'Onroerend erfgoed', hoedanigheid: Hoedanigheid.READ_ONLY, rubriek: GuiInlichtingRubriek.OnroerendErfgoed, mandatory: true, template: 'onroerend-erfgoed' },
  { inlichtingType: TypeInlichting.LokaalOnroerendErfgoed, inlichtingTypeApi: VipInlichtingType.LOKAAL_ONROEREND_ERFGOED, inlichtingVersion: 1, label: 'Lokaal onroerend erfgoed', hoedanigheid: Hoedanigheid.ADAPT, rubriek: GuiInlichtingRubriek.OnroerendErfgoed, template: 'lokaal-onroerend-erfgoed' },
]

// eslint-disable-next-line func-call-spacing
export const inlichtingTypeCanAddByRubriek = (): Map<GuiInlichtingRubriek, InlichtingInfo[]> => groupBy(inlichtingen.filter(inlichting => inlichting.hoedanigheid !== Hoedanigheid.READ_ONLY && inlichtingIsActive(inlichting.inlichtingType)), 'rubriek')

export const allModifiableInlichtingenByRubriek = (rubriek: GuiInlichtingRubriek): InlichtingInfo[] => Array.from(inlichtingTypeCanAddByRubriek().get(rubriek).values())

export const inlichtingenWithNewTemplateByRubriek = (rubriek: GuiInlichtingRubriek): Map<TypeInlichting, InlichtingInfo[]> => groupBy(inlichtingen.filter(inlichting => inlichting.rubriek === rubriek && inlichting.template), 'inlichtingType')

export const inlichtingenWithNewTemplateByInlichtingType = (): Map<TypeInlichting, InlichtingInfo[]> => groupBy(inlichtingen.filter(inlichting => inlichting.template), 'inlichtingType')

const inlichtingIsActive = (inlichtingType: TypeInlichting): boolean => {
  const inlichtingConfig = config.VUE_APP_INLICHTING_ACTIVE?.filter(setting => setting.inlichtingType === TypeInlichting[inlichtingType])
  if (inlichtingConfig?.length > 0) {
    return new Date(inlichtingConfig.at(0).activationDate) <= new Date()
  }
  return true
}
