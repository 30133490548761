import { defineComponent, PropType, ref, watch } from 'vue'
import { VergunningActiviteit, VergunningActiviteitStatus } from '@/infrastructure/bff-client/bff-client'
import { generateKey } from '@/infrastructure/helpers/general'
import useMilieuVergunningLabels
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/milieuvergunning/composables/milieuvergunning-labels'

export default defineComponent({
  name: 'MilieuActiviteit',
  props: {
    activiteit: Object as PropType<VergunningActiviteit>,
    activiteitIndex: Number,
    validationErrors: Map<string, string>,
    saving: Boolean
  },
  setup (props, { emit }) {
    const uniqueKey = ref(generateKey())
    const input = ref(props.activiteit as VergunningActiviteit)
    const { options, getLabel } = useMilieuVergunningLabels()
    const statusOptions = Object.values(VergunningActiviteitStatus)

    const makeUnique = (element: string) => `${element}-${uniqueKey.value}`

    watch(input, (newValue) => {
      emit('updated', { activiteit: newValue, index: props.activiteitIndex })
    })

    return {
      input,
      options,
      statusOptions,
      getLabel,
      makeUnique
    }
  }
})
