import {
  ExploitatieVanIngedeeldeInrichtingenOfActiviteiten,
  Kleinhandelsactiviteit,
  OmgevingsvergunningInput,
  StedenbouwkundigeHandeling,
  VerkavelingVanGronden,
  WijzigingVanKleineLandschapselementenOfVegetatie,
} from '@/infrastructure/bff-client/bff-client'
import { computed, defineComponent, onBeforeMount, PropType, ref, watch } from 'vue'
import EventBus from '@/infrastructure/events/event-bus'
import useEditHelper from '../../composables/edit-helper'
import ContentCollection from '@/components/vip-inlichting/new-version/components/content-collection/content-collection.vue'
import ExploitatieEdit from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/omgevingsvergunning/edit/project-elementen/exploitatie-edit.vue'
import KleinhandelsActiviteitenEdit from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/omgevingsvergunning/edit/project-elementen/kleinhandels-activiteiten-edit.vue'
import StedenbouwkundigeHandelingenEdit from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/omgevingsvergunning/edit/project-elementen/stedenbouwkundige-handelingen-edit.vue'
import VerkavelingVanGrondenEdit from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/omgevingsvergunning/edit/project-elementen/verkaveling-van-gronden-edit.vue'
import WijzigingOmgevingEdit from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/omgevingsvergunning/edit/project-elementen/wijziging-omgeving-edit.vue'

export enum ProjectElementType {
    exploitatie = 'exploitatie',
    kleinhandel = 'kleinhandels-activiteiten',
    stedenbouwkundigeHandeling = 'stedenbouwkundige-handelingen',
    verkaveling = 'verkaveling-van-gronden',
    wijzigingVanKleineLandschapselementenOfVegetatie = 'wijziging-omgeving'
}

export interface ProjectElement {
    type: ProjectElementType,
    element?: ExploitatieVanIngedeeldeInrichtingenOfActiviteiten | Kleinhandelsactiviteit | StedenbouwkundigeHandeling | VerkavelingVanGronden | WijzigingVanKleineLandschapselementenOfVegetatie,
    index?: number
  }

export default defineComponent({
  name: 'project-elementen-edit',
  components: {
    ContentCollection,
    WijzigingOmgevingEdit,
    VerkavelingVanGrondenEdit,
    StedenbouwkundigeHandelingenEdit,
    KleinhandelsActiviteitenEdit,
    ExploitatieEdit
  },
  props: {
    value: Object as PropType<OmgevingsvergunningInput>,
    modDisabled: Boolean,
    validationErrors: Map<string, string>
  },
  setup (props, { emit }) {
    const loaded = ref(false)
    const omgevingsEdit = ref<OmgevingsvergunningInput>(props.value)
    const projectItems = ref([])
    const projectElementToevoegenActief = ref(false)
    const activeProjectItem = ref(null)
    const { makeUnique } = useEditHelper()
    const projectElements = ref<ProjectElement[]>([])
    const addedProjectElements: ProjectElement[] = []

    const addProject = () => {
      projectElementToevoegenActief.value = true
    }
    const projectElementOptions = computed(() => {
      const projectElementen = [
        ['Exploitatie van ingedeelde inrichtingen of activiteiten', 'exploitatie'],
        ['Kleinhandelsactiviteiten', 'kleinhandels-activiteiten'],
        ['Stedenbouwkundige handeling', 'stedenbouwkundige-handelingen'],
        ['Verkaveling van gronden', 'verkaveling-van-gronden'],
        ['Wijziging van kleine landschapselementen of vegetatie', 'wijziging-omgeving']
      ]
      return projectElementen
    })

    const flattenInstruments = (input: OmgevingsvergunningInput): ProjectElement[] => {
      const mappedInstruments = []
      let type: ProjectElementType
      input.exploitatiesVanIngedeeldeInrichtingenOfActiviteiten?.forEach((expl, index) => {
        type = ProjectElementType.exploitatie
        const addedElements = addedProjectElements.filter(f => f.type === type).map((addedElement) => addedElement.index)
        if (addedElements.includes(index)) { return }
        mappedInstruments.push({ type, element: expl, index })
      })
      input.kleinhandelsactiviteiten?.forEach((klh, index) => {
        type = ProjectElementType.kleinhandel
        const addedElements = addedProjectElements.filter(f => f.type === type).map((addedElement) => addedElement.index)
        if (addedElements.includes(index)) { return }
        mappedInstruments.push({ type, element: klh, index })
      })
      input.stedenbouwkundigeHandelingen?.forEach((stedenbouwkundigeHandeling, index) => {
        type = ProjectElementType.stedenbouwkundigeHandeling
        const addedElements = addedProjectElements.filter(f => f.type === type).map((addedElement) => addedElement.index)
        if (addedElements.includes(index)) { return }
        mappedInstruments.push({ type, element: stedenbouwkundigeHandeling, index })
      })
      input.verkavelingenVanGronden?.forEach((verkaveling, index) => {
        type = ProjectElementType.verkaveling
        const addedElements = addedProjectElements.filter(f => f.type === type).map((addedElement) => addedElement.index)
        if (addedElements.includes(index)) { return }
        mappedInstruments.push({ type, element: verkaveling, index })
      })
      input.wijzigingenVanKleineLandschapselementenOfVegetatie?.forEach((wijziging, index) => {
        type = ProjectElementType.wijzigingVanKleineLandschapselementenOfVegetatie
        const addedElements = addedProjectElements.filter(f => f.type === type).map((addedElement) => addedElement.index)
        if (addedElements.includes(index)) { return }
        mappedInstruments.push({ type, element: wijziging, index })
      })
      mappedInstruments.push(...addedProjectElements)
      return mappedInstruments
    }

    const projectElementSelectieToevoegen = () => {
      const omgevingsCopy = structuredClone(omgevingsEdit.value)
      switch (activeProjectItem.value) {
        case ProjectElementType.exploitatie:
          if (omgevingsCopy.exploitatiesVanIngedeeldeInrichtingenOfActiviteiten == null) { omgevingsCopy.exploitatiesVanIngedeeldeInrichtingenOfActiviteiten = [] }
          omgevingsCopy.exploitatiesVanIngedeeldeInrichtingenOfActiviteiten.push({})
          addedProjectElements.push({ type: ProjectElementType.exploitatie, element: {}, index: omgevingsCopy.exploitatiesVanIngedeeldeInrichtingenOfActiviteiten.length - 1 })
          break
        case ProjectElementType.kleinhandel:
          if (omgevingsCopy.kleinhandelsactiviteiten == null) { omgevingsCopy.kleinhandelsactiviteiten = [] }
          omgevingsCopy.kleinhandelsactiviteiten.push({})
          addedProjectElements.push({ type: ProjectElementType.kleinhandel, element: {}, index: omgevingsCopy.kleinhandelsactiviteiten.length - 1 })
          break
        case ProjectElementType.stedenbouwkundigeHandeling:
          if (omgevingsCopy.stedenbouwkundigeHandelingen == null) { omgevingsCopy.stedenbouwkundigeHandelingen = [] }
          omgevingsCopy.stedenbouwkundigeHandelingen.push({})
          addedProjectElements.push({ type: ProjectElementType.stedenbouwkundigeHandeling, element: {}, index: omgevingsCopy.stedenbouwkundigeHandelingen.length - 1 })
          break
        case ProjectElementType.verkaveling:
          if (omgevingsCopy.verkavelingenVanGronden == null) { omgevingsCopy.verkavelingenVanGronden = [] }
          omgevingsCopy.verkavelingenVanGronden.push({})
          addedProjectElements.push({ type: ProjectElementType.verkaveling, element: {}, index: omgevingsCopy.verkavelingenVanGronden.length - 1 })
          break
        case ProjectElementType.wijzigingVanKleineLandschapselementenOfVegetatie:
          if (omgevingsCopy.wijzigingenVanKleineLandschapselementenOfVegetatie == null) { omgevingsCopy.wijzigingenVanKleineLandschapselementenOfVegetatie = [] }
          omgevingsCopy.wijzigingenVanKleineLandschapselementenOfVegetatie.push({})
          addedProjectElements.push({ type: ProjectElementType.wijzigingVanKleineLandschapselementenOfVegetatie, element: {}, index: omgevingsCopy.wijzigingenVanKleineLandschapselementenOfVegetatie.length - 1 })
          break
      }
      omgevingsEdit.value = omgevingsCopy
      projectElements.value = flattenInstruments(omgevingsCopy)
      projectElementToevoegenActief.value = false
    }

    const projectElementToevoegenAnnuleren = () => {
      projectElementToevoegenActief.value = false
    }

    const handleProjectElementChange = (projectElementToChange: { index: number, type: ProjectElementType, value: any}) => {
      const omgevingsCopy = structuredClone(omgevingsEdit.value)
      switch (projectElementToChange.type) {
        case ProjectElementType.exploitatie:
          omgevingsCopy.exploitatiesVanIngedeeldeInrichtingenOfActiviteiten[projectElementToChange.index] = projectElementToChange.value
          break
        case ProjectElementType.kleinhandel:
          omgevingsCopy.kleinhandelsactiviteiten[projectElementToChange.index] = projectElementToChange.value
          break
        case ProjectElementType.stedenbouwkundigeHandeling:
          omgevingsCopy.stedenbouwkundigeHandelingen[projectElementToChange.index] = projectElementToChange.value
          break
        case ProjectElementType.verkaveling:
          omgevingsCopy.verkavelingenVanGronden[projectElementToChange.index] = projectElementToChange.value
          break
        case ProjectElementType.wijzigingVanKleineLandschapselementenOfVegetatie:
          omgevingsCopy.wijzigingenVanKleineLandschapselementenOfVegetatie[projectElementToChange.index] = projectElementToChange.value
          break
      }
      omgevingsEdit.value = omgevingsCopy
      projectElements.value = flattenInstruments(omgevingsCopy)
    }

    const removeProjectElement = (indexToRemove: number) => {
      const itemToRemove = projectElements.value[indexToRemove]
      const omgevingsEditCopy = structuredClone(omgevingsEdit.value)
      switch (itemToRemove.type) {
        case ProjectElementType.exploitatie:
          omgevingsEditCopy.exploitatiesVanIngedeeldeInrichtingenOfActiviteiten.splice(itemToRemove.index, 1)
          break
        case ProjectElementType.kleinhandel:
          omgevingsEditCopy.kleinhandelsactiviteiten.splice(itemToRemove.index, 1)
          break
        case ProjectElementType.stedenbouwkundigeHandeling:
          omgevingsEditCopy.stedenbouwkundigeHandelingen.splice(itemToRemove.index, 1)
          break
        case ProjectElementType.verkaveling:
          omgevingsEditCopy.verkavelingenVanGronden.splice(itemToRemove.index, 1)
          break
        case ProjectElementType.wijzigingVanKleineLandschapselementenOfVegetatie:
          omgevingsEditCopy.wijzigingenVanKleineLandschapselementenOfVegetatie.splice(itemToRemove.index, 1)
          break
      }
      addedProjectElements.splice(addedProjectElements.findIndex(f => f.type === itemToRemove.type && f.index === itemToRemove.index), 1)
      omgevingsEdit.value = omgevingsEditCopy
      projectElements.value = flattenInstruments(omgevingsEditCopy)
      EventBus.$emit('close-modal', 'vl-modal-backdrop')
    }

    watch(omgevingsEdit, (newValue) => {
      emit('input', newValue)
    })

    onBeforeMount(() => {
      projectElements.value = flattenInstruments(omgevingsEdit.value)
    })

    return {
      omgevingsEdit,
      projectElements,
      projectItems,
      projectElementToevoegenActief,
      projectElementOptions,
      activeProjectItem,
      loaded,
      flattenInstruments,
      projectElementSelectieToevoegen,
      projectElementToevoegenAnnuleren,
      removeProjectElement,
      handleProjectElementChange,
      addProject,
      makeUnique
    }
  }
})
