import { computed, defineComponent, PropType, ref, watch } from 'vue'
import { formatDate } from '@/infrastructure/filters/filters'
import useOmgevingVergunningLabels
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/omgevingsvergunning/composables/omgevingsvergunning-labels'

export interface Verval {
    status?: string | undefined;
    datum?: Date | undefined;
    motivering?: string | undefined;
}

export default defineComponent({
  model: {
    event: 'verval-changed',
    prop: 'verval'
  },
  name: 'vergunning-verval',
  props: {
    verval: Object as PropType<Verval>,
    vervalRequired: Boolean,
    statusOptions: Array<string>,
    vergunningsKey: String,
    validationErrors: Map<string, string>,
  },
  setup (props, { emit }) {
    const { options, getLabel } = useOmgevingVergunningLabels()
    const vervalEdit = ref((props.verval ? props.verval : {}) as Verval)

    const motiveringLabel = computed(() => {
      switch (vervalEdit.value.status.toString()) {
        case 'INGETROKKEN':
          return 'Motivering intrekking'
        case 'DEELS_VERVALLEN':
        case 'VERVALLEN':
          return 'Motivering verval'
        case 'NIET_ONDERZOCHT':
          return 'Motivering niet onderzocht'
        case 'NIET_VERVALLEN':
          return 'Motivering niet-verval'
        default:
          return 'Motivering'
      }
    })

    const isIngetrokken = computed(() => vervalEdit.value?.status === 'INGETROKKEN')

    const vervalSelected = computed(() => {
      return (!!vervalEdit.value?.status)
    })

    const showDatum = computed(() => {
      const vervalStatusValue = vervalEdit.value?.status
      return vervalSelected.value && vervalStatusValue !== 'NIET_ONDERZOCHT' && vervalStatusValue !== 'NIET_VERVALLEN'
    })

    const showMotivering = computed(() => {
      return vervalSelected.value
    })

    const checkVerval = (status: string) => {
      if (status === 'NIET_ONDERZOCHT' || status === 'NIET_VERVALLEN') {
        vervalEdit.value.datum = undefined
        vervalEdit.value.motivering = undefined
      }
    }

    watch(() => vervalEdit.value.status, (newValue) => {
      checkVerval(newValue)
    })

    watch(vervalEdit, (newValue) => {
      emit('verval-changed', newValue)
    }, { deep: true })

    watch(() => vervalEdit.value.motivering, (newValue) => {
      const copy = structuredClone(vervalEdit.value)
      copy.motivering = newValue
      vervalEdit.value = copy
    })

    return {
      options,
      vervalEdit,
      motiveringLabel,
      isIngetrokken,
      showDatum,
      showMotivering,
      vervalSelected,
      getLabel,
      formatDate
    }
  }
})
