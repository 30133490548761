import { computed, defineComponent, PropType, watch } from 'vue'
import VipInlichtingVanToepassing
  from '@/components/vip-inlichting/components/vip-inlichting-van-toepassing/vip-inlichting-van-toepassing.vue'
import {
  BronInformatie,
  DossierStatus,
  Inlichting,
  InlichtingenIndicator,
  InlichtingInputInlichtingenIndicator,
  InlichtingRubriek,
  TypeInlichting,
  VipInlichtingDiscriminator,
  VipInlichtingType,
  WatergevoeligOpenruimtegebiedInlichting,
  WatergevoeligOpenruimtegebiedInput,
  WatergevoeligOpenruimtegebiedPlanFase
} from '@/infrastructure/bff-client/bff-client'
import VipInlichtingPreviewNew
  from '@/components/vip-inlichting/new-version/components/inlichting-preview/vip-inlichting-preview-new.vue'
import { formatDate, formatNumber } from '@/infrastructure/filters/filters'
import { inlichtingen } from '@/infrastructure/constants/inlichting-types-constants'
import useEditableInlichting from '@/components/vip-inlichting/new-version/composables/editable-inlichting.composable'
import EventBus from '@/infrastructure/events/event-bus'

function mapInlichtingToInput (vipInlichting?: WatergevoeligOpenruimtegebiedInlichting): WatergevoeligOpenruimtegebiedInput {
  return {
    inlichtingenIndicator: vipInlichting === null ? InlichtingInputInlichtingenIndicator.NEE : vipInlichting.inlichtingenIndicator as unknown as InlichtingInputInlichtingenIndicator,
    referentie: vipInlichting?.referentie,
    beschrijving: vipInlichting?.beschrijving,
    planfase: vipInlichting?.planfase ?? {} as WatergevoeligOpenruimtegebiedPlanFase,
    externeDocumentatie: vipInlichting?.externeDocumentatie,
    stedenbouwkundigeVoorschriften: vipInlichting?.stedenbouwkundigeVoorschriften,
    discriminator: VipInlichtingDiscriminator.WATERGEVOELIG_OPENRUIMTEGEBIED_V1,
    inlichtingType: VipInlichtingType.WATERGEVOELIG_OPENRUIMTEGEBIED,
  } as WatergevoeligOpenruimtegebiedInput
}

function mapInputToInlichting (input: WatergevoeligOpenruimtegebiedInput, inlichtingId: string): WatergevoeligOpenruimtegebiedInlichting {
  return {
    inlichtingId,
    inlichtingenIndicator: input.inlichtingenIndicator as unknown as InlichtingenIndicator,
    inlichtingType: VipInlichtingType.WATERGEVOELIG_OPENRUIMTEGEBIED,
    bronInformatie: {
      informatieverstrekker: 'Lokaal bestuur',
      laatsteWijzigingTijdstip: new Date()
    } as BronInformatie,
    kaarten: [],
    rubriek: InlichtingRubriek.OVERHEIDSPLANNEN,
    referentie: input.referentie,
    beschrijving: input.beschrijving,
    planfase: input.planfase,
    externeDocumentatie: input.externeDocumentatie,
    stedenbouwkundigeVoorschriften: input.stedenbouwkundigeVoorschriften,
  } as WatergevoeligOpenruimtegebiedInlichting
}

export default defineComponent({
  name: 'WatergevoeligOpenruimtegebied',
  components: { VipInlichtingVanToepassing, VipInlichtingPreviewNew },
  props: {
    inlichting: {
      type: Object as PropType<Inlichting>,
      required: false
    },
    dossierId: {
      type: String,
      required: true
    },
    dossierStatus: {
      type: Number as PropType<DossierStatus>,
      required: false
    },
    canDelete: Boolean
  },
  setup (props, { emit }) {
    const {
      refs,
      inlichtingKey,
      vipInlichting,
      inlichtingInput,
      canEdit,
      validationErrors,
      accordion,
      saving,
      addedBijlagen,
      isNietGekend,
      bijlageUploaded,
      toggleIndicator,
      inlichtingToggled,
      cancelEdit,
      saveInlichting,
      validationFailed,
      downloadUrl,
      bijlagenSaved,
      bijlageDeleted
    } = useEditableInlichting(
      props.inlichting ?? ({ inlichtingType: VipInlichtingType.WATERGEVOELIG_OPENRUIMTEGEBIED } as unknown as WatergevoeligOpenruimtegebiedInlichting),
      mapInlichtingToInput,
      mapInputToInlichting)

    const inlichtingType = TypeInlichting.WatergevoeligOpenruimtegebiedV1
    const inlichtingTitle = computed(() => inlichtingen.find(inl => inl.inlichtingType === inlichtingType).label)

    const voorschriftSuggesties: string[] = []

    const planfaseOptions =
        new Map([
          ['DEFINITIEVE_AANDUIDING', 'Definitieve aanduiding'],
          ['NATRAJECT', 'Natraject']
        ])

    const planstatusOptions = new Map([
      ['', ''],
      ['LOPENDE', 'Lopende'],
      ['INGETROKKEN', 'Ingetrokken'],
      ['STOPGEZET', 'Stopgezet'],
      ['DEFINITIEF', 'Definitief'],
      ['VERNIETIGD', 'Vernietigd'],
      ['VERNIETIGD_DOOR_RAAD_VAN_STATE', 'Vernietigd door Raad van State'],
      ['VERVALLEN', 'Vervallen']
    ])

    const getLabel = (key: string, option: string): string => {
      switch (key) {
        case 'fase':
          return planfaseOptions.get(option)
        case 'status':
          return planstatusOptions.get(option)
        default:
          return ''
      }
    }

    const validateInput = (): Map<string, string> => {
      const errors = new Map<string, string>()
      if (!inlichtingInput.value.beschrijving) {
        errors.set('plannaam', 'Plannaam is verplicht')
      }
      if (!inlichtingInput.value.referentie) {
        errors.set('planreferentie', 'Planreferentie is verplicht')
      }
      if (!inlichtingInput.value.planfase.fase) {
        errors.set('planfase', 'Fase is verplicht')
      }
      if ((inlichtingInput.value.stedenbouwkundigeVoorschriften ?? []).length === 0) {
        errors.set('voorschriften', 'Stedenbouwkundige voorschriften is verplicht')
      }
      return errors
    }

    const saved = (inlichtingId: string) => {
      saveInlichting(inlichtingId)
      emit('saved', vipInlichting.value)
      inlichtingToggled(true)
    }

    const removed = () => {
      emit('removed', vipInlichting.value.inlichtingId)
      EventBus.$emit('close-modal', 'vl-modal-backdrop')
    }

    const cancelled = () => {
      cancelEdit()
      emit('cancelled')
    }

    const addVoorschrift = (voorschrift: string) => {
      voorschriftSuggesties.push(voorschrift)
      inlichtingInput.value.stedenbouwkundigeVoorschriften.push(voorschrift)
    }

    watch(() => inlichtingInput.value.inlichtingenIndicator, (newValue) => {
      if (newValue === InlichtingInputInlichtingenIndicator.NEE) {
        inlichtingInput.value.planfase = null
      } else if (newValue === InlichtingInputInlichtingenIndicator.JA && inlichtingInput.value.planfase == null) {
        inlichtingInput.value.planfase = {} as WatergevoeligOpenruimtegebiedPlanFase
      }
    }, { immediate: true })

    return {
      vipInlichting,
      inlichtingInput,
      validationErrors,
      saving,
      accordion,
      refs,
      inlichtingKey,
      canEdit,
      addedBijlagen,
      inlichtingType,
      inlichtingTitle,
      voorschriftSuggesties,
      planfaseOptions,
      planstatusOptions,
      isNietGekend,
      bijlageUploaded,
      inlichtingToggled,
      toggleIndicator,
      validateInput,
      validationFailed,
      saved,
      removed,
      cancelled,
      addVoorschrift,
      downloadUrl,
      formatNumber,
      formatDate,
      getLabel,
      bijlagenSaved,
      bijlageDeleted
    }
  }
})
