import { ArrestVanRaadVanState, ArrestVanRaadVanStateType } from '@/infrastructure/bff-client/bff-client'
import { defineComponent, PropType, ref, watch } from 'vue'
import useOmgevingVergunningLabels from '../../composables/omgevingsvergunning-labels'
import { formatDate } from '@/infrastructure/filters/filters'
import useEditHelper from '../../composables/edit-helper'
import { BeslissingType } from './beslissingen-edit'

export default defineComponent({
  name: 'raad-van-state-edit',
  props: {
    beslissing: Object as PropType<ArrestVanRaadVanState>,
    beslissingIndex: Number,
    validationErrors: Map<string, string>,
  },
  setup (props, { emit }) {
    const { options, getLabel } = useOmgevingVergunningLabels()
    const { makeUnique } = useEditHelper()
    const beslissingEdit = ref(props.beslissing as ArrestVanRaadVanState)
    const beslissingsTypeOptions = ArrestVanRaadVanStateType

    watch(() => beslissingEdit.value, (newValue) => {
      emit('beslissing-changed', { index: props.beslissingIndex, type: BeslissingType.melding, value: newValue })
    }, { deep: true })
    return { options, beslissingEdit, getLabel, formatDate, makeUnique, beslissingsTypeOptions }
  }
})
