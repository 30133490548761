export default function useMilieuBeroepLabels () {
  const beslissingOptions = (instantie: string | undefined): Map<string, string> => {
    return instantie
      ? (statusOptions.has(instantie) ? statusOptions.get(instantie) : new Map())
      : new Map()
  }

  const beroepsinstantieOptions = new Map([
    ['DEPUTATIE', 'Deputatie'],
    ['RAAD_VAN_STATE', 'Raad van State'],
    ['RAAD_VOOR_VERGUNNINGSBETWISTINGEN', 'Raad voor Vergunningsbetwistingen'],
    ['VLAAMS_MINISTER_VAN_LEEFMILIEU', 'Vlaams minister van Leefmilieu'],
    ['VLAAMSE_OVERHEID', 'Vlaamse regering'],
  ])

  const statusOptions = new Map([
    ['DEPUTATIE', new Map([
      ['DEELS_VERGUND', 'Deels vergund'],
      ['GEEN_BESLISSING', 'Geen (tijdige) beslissing'],
      ['GEWEIGERD', 'Geweigerd'],
      ['INGETROKKEN', 'Ingetrokken'],
      ['STOPGEZET', 'Stopgezet'],
      ['VERGUND', 'Vergund']
    ])],
    ['VLAAMSE_OVERHEID', new Map([
      ['DEELS_VERGUND', 'Deels vergund'],
      ['GEEN_BESLISSING', 'Geen (tijdige) beslissing'],
      ['GEWEIGERD', 'Geweigerd'],
      ['INGETROKKEN', 'Ingetrokken'],
      ['STOPGEZET', 'Stopgezet'],
      ['VERGUND', 'Vergund'],
    ])],
    ['VLAAMS_MINISTER_VAN_LEEFMILIEU', new Map([
      ['DEELS_VERGUND', 'Deels vergund'],
      ['GEEN_BESLISSING', 'Geen (tijdige) beslissing'],
      ['GEWEIGERD', 'Geweigerd'],
      ['INGETROKKEN', 'Ingetrokken'],
      ['STOPGEZET', 'Stopgezet'],
      ['VERGUND', 'Vergund'],
    ])],
    ['RAAD_VAN_STATE', new Map([
      ['LOPENDE', 'Lopende'],
      ['GESCHORST', 'Beslissing geschorst'],
      ['NIET_GESCHORST', 'Beslissing niet geschorst'],
      ['VERNIETIGD', 'Beslissing vernietigd'],
      ['NIET_VERNIETIGD', 'Beslissing niet vernietigd'],
      ['ONONTVANKELIJK', 'Onontvankelijk']
    ])],
    ['RAAD_VOOR_VERGUNNINGSBETWISTINGEN', new Map([
      ['LOPENDE', 'Lopende'],
      ['GESCHORST', 'Beslissing geschorst'],
      ['NIET_GESCHORST', 'Beslissing niet geschorst'],
      ['VERNIETIGD', 'Beslissing vernietigd'],
      ['NIET_VERNIETIGD', 'Beslissing niet vernietigd'],
      ['ONONTVANKELIJK', 'Onontvankelijk'],
      ['VERWORPEN', 'Verworpen'],
    ]),
    ]
  ])
  return { beroepsinstantieOptions, beslissingOptions }
}
