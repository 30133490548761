import { computed, defineComponent, PropType, ref } from 'vue'
import VipInlichtingVanToepassing
  from '@/components/vip-inlichting/components/vip-inlichting-van-toepassing/vip-inlichting-van-toepassing.vue'
import {
  Beroepsprocedure,
  BronInformatie,
  DossierStatus,
  Inlichting,
  InlichtingenIndicator,
  InlichtingInputInlichtingenIndicator,
  InlichtingRubriek,
  LotInformatie,
  StedenbouwkundigVerval,
  StedenbouwkundigVervalStatus,
  TypeInlichting,
  VerkavelingsvergunningBeslissingEersteAanleg,
  VerkavelingsvergunningInlichting,
  VerkavelingsvergunningInput,
  VipApiIntrekking,
  VipInlichtingDiscriminator,
  VipInlichtingType
} from '@/infrastructure/bff-client/bff-client'
import VipInlichtingPreviewNew
  from '@/components/vip-inlichting/new-version/components/inlichting-preview/vip-inlichting-preview-new.vue'
import { formatDate, formatNumber } from '@/infrastructure/filters/filters'
import { inlichtingen } from '@/infrastructure/constants/inlichting-types-constants'
import useEditableInlichting from '@/components/vip-inlichting/new-version/composables/editable-inlichting.composable'
import EventBus from '@/infrastructure/events/event-bus'
import VipInlichtingEdit
  from '@/components/vip-inlichting/new-version/components/inlichting-edit/vip-inlichting-edit.vue'
import ContentCollection
  from '@/components/vip-inlichting/new-version/components/content-collection/content-collection.vue'
import BeroepsprocedureComponent
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/shared/beroepsprocedure/beroepsprocedure.vue'
import VervalIntrekking
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/shared/verval-intrekking/verval-intrekking.vue'
import {
  mapVervalIntrekkingInput,
  VervalIntrekkingInput
} from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/shared/verval-intrekking/verval-intrekking'
import useVervalIntrekkingLabels
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/shared/verval-intrekking/composable/verval-intrekking-labels'
import useBeroepLabels
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/shared/beroepsprocedure/composable/beroep-labels.composable'
import useVerkavelingsvergunningVervalLabels
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/verkavelingsvergunning/composables/verkavelingsvergunning-labels.composable'
import LotInformatieComponent
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/verkavelingsvergunning/lot-informatie/lot-informatie.vue'

const mapBeslissingToInput = (beslissing?: VerkavelingsvergunningBeslissingEersteAanleg): string => {
  switch (beslissing) {
    case VerkavelingsvergunningBeslissingEersteAanleg.VERNIETGN:
    case VerkavelingsvergunningBeslissingEersteAanleg.VERNIETGO:
    case VerkavelingsvergunningBeslissingEersteAanleg.WEIGERING:
    case VerkavelingsvergunningBeslissingEersteAanleg.WIJZIGGN:
      return VerkavelingsvergunningBeslissingEersteAanleg.WEIGERING
    case VerkavelingsvergunningBeslissingEersteAanleg.VERGUNNING:
    case VerkavelingsvergunningBeslissingEersteAanleg.WIJZIGGO:
      return VerkavelingsvergunningBeslissingEersteAanleg.VERGUNNING
    default:
      return beslissing
  }
}

const mapInlichtingToInput = (vipInlichting?: VerkavelingsvergunningInlichting): VerkavelingsvergunningInput => {
  return {
    inlichtingenIndicator: vipInlichting === null ? InlichtingInputInlichtingenIndicator.NEE : vipInlichting.inlichtingenIndicator as unknown as InlichtingInputInlichtingenIndicator,
    discriminator: VipInlichtingDiscriminator.VERKAVELINGSVERGUNNING_V1,
    inlichtingType: VipInlichtingType.VERKAVELINGSVERGUNNING,
    referentie: vipInlichting?.referentie,
    beschrijving: vipInlichting?.beschrijving,
    gemeentelijkDossiernummer: vipInlichting?.gemeentelijkDossiernummer,
    aardAanvraag: vipInlichting?.aardAanvraag,
    beslissingEersteAanleg: mapBeslissingToInput(vipInlichting?.beslissingEersteAanleg),
    datumBeslissing: vipInlichting?.datumBeslissing,
    vergunningVerlenendeOverheid: vipInlichting?.vergunningVerlenendeOverheid,
    voorwaarden: vipInlichting?.voorwaarden,
    beroepsprocedures: vipInlichting?.beroepsprocedures ?? [],
    verval: vipInlichting?.verval,
    intrekking: vipInlichting?.intrekking,
    lotInformatie: vipInlichting?.lotInformatie ?? [],
    vergunningLasten: vipInlichting?.vergunningLasten ?? [],
    verkoopbaarheidsattestDatum: vipInlichting?.verkoopbaarheidsattestDatum,
    externeDocumentatie: vipInlichting?.externeDocumentatie
  } as VerkavelingsvergunningInput
}

const mapInputToInlichting = (input: VerkavelingsvergunningInput, inlichtingId: string): VerkavelingsvergunningInlichting => {
  return {
    inlichtingId,
    inlichtingenIndicator: input.inlichtingenIndicator as unknown as InlichtingenIndicator,
    inlichtingType: VipInlichtingType.VERKAVELINGSVERGUNNING,
    version: 1,
    bronInformatie: {
      informatieverstrekker: 'Lokaal bestuur',
      laatsteWijzigingTijdstip: new Date()
    } as BronInformatie,
    kaarten: [],
    rubriek: InlichtingRubriek.VERGUNNINGEN,
    referentie: input.referentie,
    beschrijving: input.beschrijving,
    gemeentelijkDossiernummer: input.gemeentelijkDossiernummer,
    aardAanvraag: input.aardAanvraag,
    beslissingEersteAanleg: input.beslissingEersteAanleg,
    datumBeslissing: input.datumBeslissing,
    einddatumBeslissing: input.einddatumBeslissing,
    vergunningVerlenendeOverheid: input.vergunningVerlenendeOverheid,
    voorwaarden: input.voorwaarden,
    beroepsprocedures: input.beroepsprocedures,
    verval: input.verval,
    intrekking: input.intrekking,
    lotInformatie: input.lotInformatie,
    vergunningLasten: input.vergunningLasten,
    verkoopbaarheidsattestDatum: input.verkoopbaarheidsattestDatum,
    externeDocumentatie: input.externeDocumentatie
  } as VerkavelingsvergunningInlichting
}

export default defineComponent({
  name: 'StedenbouwkundigeVergunning',
  components: {
    LotInformatie: LotInformatieComponent,
    VervalIntrekking,
    ContentCollection,
    Beroepsprocedure: BeroepsprocedureComponent,
    VipInlichtingVanToepassing,
    VipInlichtingPreviewNew,
    VipInlichtingEdit
  },
  props: {
    inlichting: {
      type: Object as PropType<Inlichting>,
      required: false
    },
    dossierId: {
      type: String,
      required: true
    },
    dossierStatus: {
      type: Number as PropType<DossierStatus>,
      required: false
    },
    canDelete: Boolean
  },
  setup (props, { emit }) {
    const {
      refs,
      inlichtingKey,
      vipInlichting,
      inlichtingInput,
      canEdit,
      validationErrors,
      accordion,
      saving,
      addedBijlagen,
      isNietGekend,
      bijlageUploaded,
      toggleIndicator,
      inlichtingToggled,
      cancelEdit,
      saveInlichting,
      validationFailed,
      downloadUrl,
      bijlagenSaved,
      bijlageDeleted
    } = useEditableInlichting(
      props.inlichting ?? ({ inlichtingType: VipInlichtingType.VERKAVELINGSVERGUNNING } as unknown as VerkavelingsvergunningInlichting),
      mapInlichtingToInput,
      mapInputToInlichting)

    const vervalIntrekkingInput = ref(mapVervalIntrekkingInput(vipInlichting.value.verval, vipInlichting.value.intrekking) as VervalIntrekkingInput)

    const inlichtingType = TypeInlichting.Verkavelingsvergunning
    const inlichtingTitle = computed(() => inlichtingen.find(inl => inl.inlichtingType === inlichtingType).label)
    const beroepAangetekend = computed({
      get () {
        return inlichtingInput.value.inlichtingenIndicator === InlichtingInputInlichtingenIndicator.JA &&
            (inlichtingInput.value.beroepsprocedures ?? []).length > 0
      },
      set (value) {
        const input = structuredClone(inlichtingInput.value)
        if (value) {
          input.beroepsprocedures = (props.inlichting as VerkavelingsvergunningInlichting).beroepsprocedures ?? []
          if (input.beroepsprocedures.length === 0) {
            input.beroepsprocedures.push({})
          }
        } else {
          input.beroepsprocedures = []
        }
        inlichtingInput.value = input
      },
    })

    const { intrekkingPartijOptions } = useVervalIntrekkingLabels()
    const { vervalTekst, vervalOptions } = useVerkavelingsvergunningVervalLabels()

    const optionsBeslissing = new Map<string, string>([
      ['GEDEELTELIJK_VOORWAARDELIJK_VERGUND', 'Gedeeltelijk voorwaardelijk vergund'],
      ['STILWEIGER', 'Geweigerd (stilzwijgend)'],
      ['UITBLIJF', 'Geen (tijdige) beslissing'],
      ['VERGUNNING', 'Vergund'],
      ['VERNIETGN', 'Geweigerd'],
      ['VERNIETGO', 'Geweigerd'],
      ['VOORWAARDELIJK_VERGUND', 'Voorwaardelijk vergund'],
      ['WEIGERING', 'Geweigerd'],
      ['WIJZIGGN', 'Geweigerd'],
      ['WIJZIGGO', 'Vergund']
    ])

    const optionsBeslissingInput = new Map<string, string>([
      ['UITBLIJF', 'Geen (tijdige) beslissing'],
      ['STILWEIGER', 'Geweigerd (stilzwijgend)'],
      ['WEIGERING', 'Geweigerd'],
      ['VERGUNNING', 'Vergund'],
      ['VOORWAARDELIJK_VERGUND', 'Voorwaardelijk vergund'],
      ['GEDEELTELIJK_VOORWAARDELIJK_VERGUND', 'Gedeeltelijk voorwaardelijk vergund']
    ])

    const optionsAard = new Map<string, string>([
      ['', ''],
      ['NIEUW', 'Nieuw'],
      ['WIJZIGING', 'Wijziging'],
    ])

    const optionsInstantie = new Map([
      ['', ''],
      ['COLLEGE_BURGEMEESTER_SCHEPENEN', 'College van Burgemeester en Schepenen'],
      ['PROVINCIE', 'Provincie'],
      ['VLAAMS_GEWEST', 'Vlaams Gewest']
    ])

    const optionsLasten = new Map<string, string>([
      ['FINANCIELE_GELDSOM', 'Geldsom betalen'],
      ['GRATIS_EIGENDOMSOVERDRACHT', 'Eigendom overdragen'],
      ['UITVOEREN_VAN_WERKEN_IN_NATURA', 'Werken uitvoeren'],
    ])

    const optionsVervalInput = new Map<string, string>([
      ['', ''],
      ['NIET_ONDERZOCHT', 'Niet onderzocht'],
      ['NIET_VERVALLEN', 'Niet vervallen'],
      ['DEELS_VERVALLEN', 'Deels vervallen'],
      ['VERVALLEN', 'Vervallen'],
      ['VERZAAKT', 'Verzaakt'],
      ['HERZIEN_BPA_RUP', 'Herzien door BPA of RUP'],
      ['HERZIEN_COLLEGE', 'Herzien door college'],
      ['OPGEHEVEN_BPA_RUP', 'Opgeheven door BPA of RUP'],
      ['OPGEHEVEN_COLLEGE', 'Opgeheven door college'],
      ['INGETROKKEN', 'Ingetrokken'],
    ])

    const { beroepsinstantieOptions, beslissingOptions: beroepBeslissingOptions } = useBeroepLabels()

    const validateInput = (): Map<string, string> => {
      const errors = new Map<string, string>()
      if (inlichtingInput.value.beslissingEersteAanleg == null) {
        errors.set('beslissing', 'Beslissing is verplicht')
      }
      if (inlichtingInput.value.datumBeslissing == null) {
        errors.set('datumBeslissing', 'Beslist op is verplicht')
      }
      inlichtingInput.value.beroepsprocedures?.forEach((procedure, index) => {
        if (procedure.beroepsinstantie == null) {
          errors.set(`beroep-${index}-instantie`, 'Instantie is verplicht')
        }
        if (procedure.beslissing == null) {
          errors.set(`beroep-${index}-beslissing`, 'Beslissing is verplicht')
        }
      })
      if (inlichtingInput.value.intrekking != null) {
        if (inlichtingInput.value.intrekking.partij == null) {
          errors.set('intrekking-partij', 'Ingetrokken door is verplicht')
        }
      }
      inlichtingInput.value.lotInformatie?.forEach((lot, index) => {
        if (!lot.nummer) {
          errors.set(`lot-${index}-nummer`, 'Lotnummer is verplicht')
        }
        if (lot.status == null) {
          errors.set(`lot-${index}-status`, 'Verval is verplicht')
        }
      })
      return errors
    }

    const saved = (inlichtingId: string) => {
      saveInlichting(inlichtingId)
      emit('saved', vipInlichting.value)
      inlichtingToggled(true)
    }

    const removed = () => {
      emit('removed', vipInlichting.value.inlichtingId)
      EventBus.$emit('close-modal', 'vl-modal-backdrop')
    }

    const cancelled = () => {
      cancelEdit()
      emit('cancelled')
    }

    const addBeroep = () => {
      const input = structuredClone(inlichtingInput.value)
      input.beroepsprocedures.push({})
      inlichtingInput.value = input
    }

    const removeBeroep = (index: number) => {
      const input = structuredClone(inlichtingInput.value)
      input.beroepsprocedures.splice(index, 1)
      inlichtingInput.value = input
      EventBus.$emit('close-modal', 'vl-modal-backdrop')
    }

    const updateBeroep = (procedure: Beroepsprocedure, procedureIndex: number) => {
      const input = structuredClone(inlichtingInput.value)
      input.beroepsprocedures[procedureIndex] = procedure
      inlichtingInput.value = input
    }

    const addLot = () => {
      const input = structuredClone(inlichtingInput.value)
      input.lotInformatie.push({})
      inlichtingInput.value = input
    }

    const removeLot = (index: number) => {
      const input = structuredClone(inlichtingInput.value)
      input.lotInformatie.splice(index, 1)
      inlichtingInput.value = input
      EventBus.$emit('close-modal', 'vl-modal-backdrop')
    }

    const updateLot = (lot: LotInformatie, index: number) => {
      const input = structuredClone(inlichtingInput.value)
      input.lotInformatie[index] = lot
      inlichtingInput.value = input
    }

    const handleVervalIntrekking = (vervalIntrekkingInput: VervalIntrekkingInput) => {
      const input = structuredClone(inlichtingInput.value)
      if (vervalIntrekkingInput.status === StedenbouwkundigVervalStatus.INGETROKKEN) {
        input.intrekking = {
          partij: vervalIntrekkingInput.partij,
          datum: vervalIntrekkingInput.datum,
          motivering: vervalIntrekkingInput.motivering
        } as VipApiIntrekking
        input.verval = null
      } else {
        input.verval = {
          status: vervalIntrekkingInput.status,
          datum: vervalIntrekkingInput.datum,
          motivering: vervalIntrekkingInput.motivering
        } as StedenbouwkundigVerval
        input.intrekking = null
      }
      inlichtingInput.value = input
    }

    return {
      vipInlichting,
      inlichtingInput,
      vervalIntrekkingInput,
      beroepAangetekend,
      validationErrors,
      saving,
      accordion,
      refs,
      inlichtingKey,
      canEdit,
      addedBijlagen,
      inlichtingType,
      inlichtingTitle,
      optionsAard,
      optionsInstantie,
      optionsLasten,
      optionsBeslissing,
      optionsBeslissingInput,
      vervalOptions,
      optionsVervalInput,
      intrekkingPartijOptions,
      isNietGekend,
      beroepsinstantieOptions,
      beroepBeslissingOptions,
      bijlageUploaded,
      inlichtingToggled,
      toggleIndicator,
      validateInput,
      validationFailed,
      saved,
      removed,
      cancelled,
      downloadUrl,
      bijlagenSaved,
      bijlageDeleted,
      addBeroep,
      removeBeroep,
      updateBeroep,
      addLot,
      removeLot,
      updateLot,
      handleVervalIntrekking,
      vervalTekst,
      formatNumber,
      formatDate
    }
  }
})
