import { BeslissingInEersteAdministratieveAanleg, BeslissingInEersteAdministratieveAanlegInstantie, BeslissingInEersteAdministratieveAanlegType } from '@/infrastructure/bff-client/bff-client'
import { computed, defineComponent, PropType, ref, watch } from 'vue'
import useOmgevingVergunningLabels from '../../composables/omgevingsvergunning-labels'
import { formatDate } from '@/infrastructure/filters/filters'
import useEditHelper from '../../composables/edit-helper'
import { BeslissingType } from './beslissingen-edit'

export default defineComponent({
  name: 'eerste-administratieve-aanleg-edit',
  props: {
    beslissing: Object as PropType<BeslissingInEersteAdministratieveAanleg>,
    beslissingIndex: Number,
    validationErrors: Map<string, string>,
  },
  setup (props, { emit }) {
    const { options, getLabel } = useOmgevingVergunningLabels()
    const { makeUnique } = useEditHelper()
    const beslissingEdit = ref(props.beslissing as BeslissingInEersteAdministratieveAanleg)
    const beslissingsTypeOptions = BeslissingInEersteAdministratieveAanlegType
    const beslistDoorOptions = BeslissingInEersteAdministratieveAanlegInstantie
    const isTijdelijkVergund = computed(() => {
      return (props.beslissing as any).type === BeslissingInEersteAdministratieveAanlegType.TIJDELIJK_VERGUND
    })

    watch(() => beslissingEdit.value, (newValue) => {
      emit('beslissing-changed', { index: props.beslissingIndex, type: BeslissingType.melding, value: newValue })
    })
    return { options, beslissingEdit, getLabel, formatDate, makeUnique, beslissingsTypeOptions, beslistDoorOptions, isTijdelijkVergund }
  }
})
