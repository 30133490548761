export default function useStedenbouwkundigeOvertredingLabels () {
  const options =
    new Map<string, Map<string, string>>([
      ['overtredingsStatus', new Map<string, string>([
        ['', ''],
        ['BESTUURLIJKE_MAATREGEL', 'Bestuurlijke maatregel'],
        ['DEELS_VERGUND', 'Deels vergund'],
        ['DEELS_VERGUND_DEELS_HERSTELD', 'Deels vergund - deels hersteld'],
        ['EINDE_OVERTREDING', 'Einde overtreding'],
        ['GEREGULARISEERD', 'Geregulariseerd (of vergund)'],
        ['HERSTELD', 'Hersteld'],
        ['LOPENDE', 'Lopende'],
        ['MINNELIJKE_SCHIKKING', 'Minnelijke schikking'],
        ['NIET_OPGEVOLGD', 'Niet opgevolgd'],
        ['VERDER_ONDERZOEK_VEREIST', 'Verder onderzoek vereist'],
      ])],
      ['gerelateerdeWetgeving', new Map<string, string>([
        ['VOOR_OMGEVINGSVERGUNNINGENDECREET', 'Vóór decreet'],
        ['NA_OMGEVINGSVERGUNNINGENDECREET', 'Sinds decreet']
      ])],
      ['aanmaningOpsteller', new Map<string, string>([
        ['GEMEENTE', 'Gemeente'],
        ['GEWEST', 'Gewest: Departement Omgeving Afdeling Handhaving']
      ])],
      ['aanmaningIntentieOvertreder', new Map<string, string>([
        ['', ''],
        ['AANPASSINGSWERKEN', 'Aanpassingswerken uitvoeren naar een vergunde/gemelde toestand'],
        ['ANDERE', 'Andere gevallen, bijvoorbeeld combinaties van de vorige'],
        ['HERSTELLEN', 'Herstellen oorspronkelijke toestand'],
        ['MELDING', 'Melding verrichten'],
        ['REGULARISATIEVERGUNNING', 'Regularisatievergunning aanvragen'],
        ['STAKING_GEBRUIK', 'Strijdig gebruik staken'],
        ['VERGUNNING', 'Vergunning aanvragen'],
      ])],
      ['procesVerbaalBeslissingParket', new Map<string, string>([
        ['', ''],
        ['SEPONERING', 'Seponering'],
        ['VERVOLGING', 'Vervolging'],
        ['GEEN_GERECHTELIJKE_VERVOLGING', 'Geen gerechtelijke vervolging'],
      ])],
      ['seponeringBestuurlijkeSanctie', new Map<string, string>([
        ['', ''],
        ['BESTUURLIJKE_GELDBOETE', 'Bestuurlijke geldboete opgelegd'],
        ['GEEN_BESTUURLIJKE_SANCTIE', 'Geen bestuurlijke sanctie'],
        ['SEPONERING', 'Seponering'],
      ])],
      ['vervolgingVorderingInstantie', new Map<string, string>([
        ['BURGERLIJKE_PARTIJ', 'Burgerlijke partij'],
        ['GEMEENTE', 'Gemeente'],
        ['VLAAMSE_OMGEVINGSINSPECTIE', 'Vlaamse Omgevingsinspectie'],
      ])],
      ['adviesHogeRaadVoorHandhavingsuitvoeringSoortAdvies', new Map<string, string>([
        ['NEGATIEF', 'Negatief advies'],
        ['POSITIEF', 'Positief advies'],
      ])],
      ['rechtbankVanEersteAanlegTypeRechtbank', new Map<string, string>([
        ['BURGERLIJK', 'Burgerlijke rechtbank'],
        ['CORRECTIONEEL', 'Correctionele rechtbank'],
      ])],
      ['bestuurlijkeHerstelmaatregelInstantie', new Map<string, string>([
        ['GEMEENTE', 'Gemeente'],
        ['GEWEST', 'Gewest'],
      ])],
      ['bestuurlijkeHerstelmaatregelType', new Map<string, string>([
        ['', ''],
        ['BESTUURSDWANG', 'Bestuursdwang'],
        ['LAST_ONDER_DWANGSOM', 'Last onder dwangsom'],
      ])],
      ['inbreukBestuurlijkeSanctie', new Map<string, string>([
        ['BESTUURLIJKE_GELDBOETE', 'Bestuurlijke geldboete opgelegd'],
        ['GEEN_BESTUURLIJKE_SANCTIE', 'Geen bestuurlijke sanctie'],
        ['SEPONERING', 'Seponering'],
      ])],
      ['stakingsbevelOpsteller', new Map<string, string>([
        ['GEMEENTE', 'Gemeente'],
        ['GEWEST', 'Gewest'],
        ['POLITIE', 'Politie'],
      ])],
      ['pvTypeRechtbank', new Map<string, string>([
        ['HogeRaadVoorHandhavingsuitvoering', 'Hoge Raad voor Handhavingsuitvoering'],
        ['RechtbankEersteAanlegBurgerlijk', 'Rechtbank van eerste aanleg (burgerlijk)'],
        ['RechtbankEersteAanlegCorrectioneel', 'Rechtbank van eerste aanleg (correctioneel)'],
        ['HofVanBeroep', 'Hof van beroep'],
        ['HofVanCassatie', 'Hof van Cassatie'],
      ])]
    ])

  const getLabel = (optionKey: string, valueKey: string): string => {
    const list = options.get(optionKey)
    if (list.has(valueKey)) {
      return list.get(valueKey)
    } else {
      return valueKey
    }
  }

  return { options, getLabel }
}
