import {
  Kleinhandelsactiviteit,
  KleinhandelsactiviteitType,
  KleinhandelsactiviteitVervalStatus
} from '@/infrastructure/bff-client/bff-client'
import { defineComponent, PropType, ref, watch } from 'vue'
import useOmgevingVergunningLabels from '../../composables/omgevingsvergunning-labels'
import { formatDate } from '@/infrastructure/filters/filters'
import useEditHelper from '../../composables/edit-helper'
import { ProjectElementType } from './project-elementen-edit'
import VergunningVerval
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/omgevingsvergunning/edit/vergunning-verval/vergunning-verval.vue'

export default defineComponent({
  name: 'kleinhandels-activiteiten-edit',
  components: { VergunningVerval },
  props: {
    projectElement: Object as PropType<Kleinhandelsactiviteit>,
    projectElementIndex: Number,
    validationErrors: Map<string, string>,
  },
  setup (props, { emit }) {
    const { options, getLabel } = useOmgevingVergunningLabels()
    const { makeUnique } = useEditHelper()
    const projectElementEdit = ref(props.projectElement as Kleinhandelsactiviteit)
    const handelsactiviteitenOptions = KleinhandelsactiviteitType
    const vervalOptions = Object.values(KleinhandelsactiviteitVervalStatus)

    watch(() => projectElementEdit.value, (newValue) => {
      emit('project-element-changed', { index: props.projectElementIndex, type: ProjectElementType.kleinhandel, value: newValue })
    }, { deep: true })
    return { options, projectElementEdit, handelsactiviteitenOptions, vervalOptions, getLabel, formatDate, makeUnique }
  }
})
