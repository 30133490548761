export default function useRisicogrondLabels () {
  const options = new Map([
    ['rubriekType', new Map([
      ['', ''],
      ['VLAREBO_2008', 'Vlarebo 2008'],
      ['VLAREM', 'Vlarem'],
      ['ARAB', 'ARAB'],
    ])],
    ['vlareboCode', new Map([
      ['CATEGORIE_A', 'Categorie A'],
      ['CATEGORIE_A_ASTERISK', 'Categorie A*'],
      ['CATEGORIE_B', 'Categorie B'],
      ['CATEGORIE_B_ASTERISK', 'Categorie B*'],
      ['CATEGORIE_C', 'Categorie C'],
      ['CATEGORIE_I', 'Categorie I'],
      ['CATEGORIE_O', 'Categorie O'],
      ['CATEGORIE_A_S', 'Categorie A - Situatierapport'],
      ['CATEGORIE_A_ASTERISK_S', 'Categorie A - Situatierapport'],
      ['CATEGORIE_B_S', 'Categorie B - Situatierapport'],
      ['CATEGORIE_B_ASTERISK_S', 'Categorie B* - Situatierapport'],
      ['CATEGORIE_O_S', 'Categorie O - Situatierapport'],
      ['CATEGORIE_A_I', 'Categorie A - Inventarisatieplicht'],
      ['CATEGORIE_A_ASTERISK_I', 'Categorie A* - Inventarisatieplicht'],
      ['CATEGORIE_B_I', 'Categorie B - Inventarisatieplicht'],
      ['CATEGORIE_B_ASTERISK_I', 'Categorie B* - Inventarisatieplicht'],
      ['CATEGORIE_O_I', 'Categorie O - Inventarisatieplicht'],
      ['NIET_GEKEND', 'Niet gekend'],
    ])],
    ['vlareboDescription', new Map([
      ['CATEGORIE_A', 'Oriënterend onderzoek verplicht bij overdracht, sluiting en faillissement, en om de twintig jaar'],
      ['CATEGORIE_A_ASTERISK', 'Inrichting waarvoor: a) bij uitsluitend bovengrondse opslag conform het Bodemdecreet van 27 oktober 2006 en het VLAREBO van 14 december 2007 een oriënterend bodemonderzoek verplicht is bij overdracht, onteigening, sluiting en faillissement; b) bij ondergrondse opslag of bij combinatie van ondergrondse of bovengrondse opslag conform het Bodemdecreet van 27 oktober 2006 en het VLAREBO van 14 december 2007 een oriënterend onderzoek verplicht is bij overdracht, sluiting en faillissement en om de twintig jaar'],
      ['CATEGORIE_B', 'Oriënterend onderzoek verplicht bij overdracht, sluiting en faillissement, en om de tien jaar'],
      ['CATEGORIE_B_ASTERISK', 'Inrichting waarvoor: a) bij uitsluitend bovengrondse opslag conform het Bodemdecreet van 27 oktober 2006 en het VLAREBO van 14 december 2007 een oriënterend onderzoek verplicht is bij overdracht, sluiting en faillissement en om de twintig jaar; b) bij ondergrondse opslag of bij combinatie van ondergrondse of bovengrondse opslag conform het Bodemdecreet van 27 oktober 2006 en het VLAREBO van 14 december 2007 een oriënterend onderzoek verplicht is bij overdracht, sluiting en faillissement en om de tien jaar'],
      ['CATEGORIE_C', 'Oriënterend onderzoek verplicht bij overdracht, sluiting en faillissement, en om de vijf jaar'],
      ['CATEGORIE_O', 'Oriënterend onderzoek verplicht bij overdracht, sluiting en faillissement'],
      ['CATEGORIE_S', 'De exploitant dient onder leiding van een bodemsaneringsdeskundige een oriënterend bodemonderzoek uit te laten voeren en het verslag ervan aan de OVAM te bezorgen op verschillende tijdstippen, zie Vlarem I bijlage 1'],
    ])],
    ['schrappingReden', new Map([
      ['', ''],
      ['ANDERE', 'Andere'],
      ['BIJKOMENDE_ONDERZOEK_MET_BEWIJSSTUKKEN', 'Bijkomend onderzoek met bewijsstukken andere'],
      ['FOUT_IN_PERCEEL_NOTATIE', 'Fout in perceelnotatie'],
      ['GEMOTIVEERDE_VERKLARING_BODEMSANERINGSDESKUNDIGE', 'Gemotiveerde verklaring bodemsaneringsdeskundige'],
      ['KADASTRALE_WIJZIGINGEN', 'Kadastrale wijzigingen'],
      ['MATERIELE_VERGISSING', 'Materiële vergissing'],
      ['NIET_MEER_INGEDEELD_ALS_RISICO', 'Niet meer ingedeeld als risicoactiviteit'],
      ['NOOIT_UITGEBAAT', 'Nooit uitgebaat'],
      ['ONTERECHTE_OPNAME_IN_VERGUNNING', 'Onterechte opname in vergunning'],
      ['RISICO_ACTIVITEIT_NOOIT_UITGEVOERD', 'Nooit uitgevoerd'],
      ['VERSCHILLEND_VAN_WERKELIJKE_TOESTAND', 'Vergunde toestand komt niet overeen met werkelijke toestand'],
    ])]
  ])

  const getLabel = (key: string, option: string): string => options.get(key).get(option)

  const toelichting = 'Het lokaal bestuur voegt hier informatie toe die gekend is uit een milieuvergunning. Mogelijks worden niet alle activiteiten vermeld die opgenomen zijn in de milieuvergunning en geleid hebben tot de risicogrond.' +
      '<br /><br />' +
      'De aanvrager neemt best contact op met de stad of gemeente om deze informatie te verkrijgen.' +
      'Opgelet: er kunnen ook steeds niet-vergunde activiteiten plaatsgevonden hebben waar het lokaal bestuur geen informatie over heeft.'

  return { options, toelichting, getLabel }
}
