import { StedenbouwkundigeHandeling, StedenbouwkundigVervalStatus } from '@/infrastructure/bff-client/bff-client'
import { defineComponent, PropType, ref, watch } from 'vue'
import useOmgevingVergunningLabels from '../../composables/omgevingsvergunning-labels'
import { formatDate } from '@/infrastructure/filters/filters'
import { ProjectElementType } from './project-elementen-edit'
import useEditHelper from '../../composables/edit-helper'
import useInlichtingValidation from '@/components/vip-inlichting/new-version/composables/inlichting-validation.composable'
import VergunningVerval
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/omgevingsvergunning/edit/vergunning-verval/vergunning-verval.vue'

export default defineComponent({
  name: 'stedenbouwkundige-handelingen-edit',
  components: { VergunningVerval },
  props: {
    projectElement: Object as PropType<StedenbouwkundigeHandeling>,
    projectElementIndex: Number,
    validationErrors: Map<string, string>,
  },
  setup (props, { emit }) {
    const { options, getLabel } = useOmgevingVergunningLabels()
    const { hasError, getError } = useInlichtingValidation(props.validationErrors)

    const { makeUnique } = useEditHelper()
    const projectElementEdit = ref(props.projectElement as StedenbouwkundigeHandeling)
    const aardAanvraagOptions = [
      'Gronden gebruiken, aanleggen of inrichten voor de opslag van gebruikte of afgedankte voertuigen, van allerhande materialen, materieel of afval',
      'Gronden gebruiken, aanleggen of inrichten voor parkeren van voertuigen, wagens of aanhangwagens',
      'Gronden gebruiken, aanleggen of inrichten voor plaatsen van 1 of meer verplaatsbare inrichtingen of rollend materieel die hoofdzakelijk publicitaire doeleinden worden gebruikt',
      'Niet gekend',
      'Nieuwbouw bijgebouw',
      'Nieuwbouw eengezinswoning',
      'Nieuwbouw handel, horeca, diensten (bvb banken, winkels, winkelcentra, …)',
      'Nieuwbouw industrie, ambacht',
      'Nieuwbouw infrastructuren',
      'Nieuwbouw kantoren',
      'Nieuwbouw land- en tuinbouw',
      'Nieuwbouw meergezinswoning',
      'Nieuwbouw gemeenschapsvoorziening of openbaar nut (scholen, religieuze gebouwen, ziekenhuizen, rusthuizen, …)',
      'Nieuwbouw toerisme en recreatie',
      'Ontbossen',
      'Publiciteitsinrichtingen',
      'Reliëfwijziging',
      'Slopen bijgebouw',
      'Slopen eengezinswoning',
      'Slopen handel, horeca',
      'Slopen industrie, ambacht',
      'Slopen infrastructuren',
      'Slopen kantoren", "Slopen kantoren',
      'Slopen land- en tuinbouw',
      'Slopen meergezinswoning", "Slopen meergezinswoning',
      'Slopen gemeenschapsvoorziening of openbaar nut',
      'Slopen toerisme en recreatie',
      'Vellen hoogstammige bomen',
      'Verbouwen van/tot of uitbreiden van bijgebouw',
      'Verbouwen van/tot of uitbreiden van eengezinswoning',
      'Verbouwen van/tot of uitbreiden van handel, horeca, diensten',
      'Verbouwen van/tot of uitbreiden van industrie, ambacht',
      'Verbouwen van/tot of uitbreiden van infrastructuren',
      'Verbouwen van/tot of uitbreiden van kantoren',
      'Verbouwen van/tot of uitbreiden van land - en tuinbouw',
      'Verbouwen van/tot of uitbreiden van meergezinswoning',
      'Verbouwen van/tot of uitbreiden van gemeenschapsvoorziening of openbaar nut',
      'Verbouwen van/tot of uitbreiden van toerisme en recreatie',
      'Verbouwen zonder functiewijziging en met wijziging van het aantal woongelegenheden',
      'Wijzigen aantal woongelegenheden bestemd voor de huisvesting van een gezin of een alleenstaande'
    ]

    const vervalOptions = Object.values(StedenbouwkundigVervalStatus)

    watch(() => projectElementEdit.value, (newValue) => {
      emit('project-element-changed', { index: props.projectElementIndex, type: ProjectElementType.stedenbouwkundigeHandeling, value: newValue })
    }, { deep: true })
    return { options, hasError, getError, projectElementEdit, getLabel, formatDate, makeUnique, aardAanvraagOptions, vervalOptions }
  }
})
