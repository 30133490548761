import { render, staticRenderFns } from "./pv-uitspraak-edit.vue?vue&type=template&id=f25cd938"
import script from "./pv-uitspraak-edit.ts?vue&type=script&lang=ts&external"
export * from "./pv-uitspraak-edit.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports