import { computed, defineComponent, PropType, ref } from 'vue'
import VipInlichtingVanToepassing
  from '@/components/vip-inlichting/components/vip-inlichting-van-toepassing/vip-inlichting-van-toepassing.vue'
import {
  Beroepsprocedure, BeroepsprocedureInstantie, BeroepsprocedureRaadVoorVergunningsbetwistingen,
  BronInformatie,
  DossierStatus,
  Inlichting,
  InlichtingenIndicator,
  InlichtingInputInlichtingenIndicator,
  InlichtingRubriek,
  StedenbouwkundigeMeldingInlichting,
  StedenbouwkundigeMeldingInput, StedenbouwkundigVerval,
  StedenbouwkundigVervalStatus,
  TypeInlichting, VipApiIntrekking,
  VipInlichtingDiscriminator,
  VipInlichtingType
} from '@/infrastructure/bff-client/bff-client'
import VipInlichtingPreviewNew
  from '@/components/vip-inlichting/new-version/components/inlichting-preview/vip-inlichting-preview-new.vue'
import { formatDate, formatNumber } from '@/infrastructure/filters/filters'
import { inlichtingen } from '@/infrastructure/constants/inlichting-types-constants'
import useEditableInlichting from '@/components/vip-inlichting/new-version/composables/editable-inlichting.composable'
import EventBus from '@/infrastructure/events/event-bus'
import VipInlichtingEdit
  from '@/components/vip-inlichting/new-version/components/inlichting-edit/vip-inlichting-edit.vue'
import ContentCollection
  from '@/components/vip-inlichting/new-version/components/content-collection/content-collection.vue'
import BeroepsprocedureComponent
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/shared/beroepsprocedure/beroepsprocedure.vue'
import VervalIntrekking
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/shared/verval-intrekking/verval-intrekking.vue'
import {
  mapVervalIntrekkingInput,
  VervalIntrekkingInput
} from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/shared/verval-intrekking/verval-intrekking'
import useVervalIntrekkingLabels
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/shared/verval-intrekking/composable/verval-intrekking-labels'
import useBeroepLabels
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/shared/beroepsprocedure/composable/beroep-labels.composable'

function mapInlichtingToInput (vipInlichting?: StedenbouwkundigeMeldingInlichting): StedenbouwkundigeMeldingInput {
  return {
    inlichtingenIndicator: vipInlichting === null ? InlichtingInputInlichtingenIndicator.NEE : vipInlichting.inlichtingenIndicator as unknown as InlichtingInputInlichtingenIndicator,
    discriminator: VipInlichtingDiscriminator.STEDENBOUWKUNDIGE_MELDING_V1,
    inlichtingType: VipInlichtingType.STEDENBOUWKUNDIGE_MELDING,
    referentie: vipInlichting?.referentie,
    beschrijving: vipInlichting?.beschrijving,
    gemeentelijkDossiernummer: vipInlichting?.gemeentelijkDossiernummer,
    aardAanvraag: vipInlichting?.aardAanvraag,
    beslissingEersteAanleg: vipInlichting?.beslissingEersteAanleg,
    datumBeslissing: vipInlichting?.datumBeslissing,
    vergunningVerlenendeOverheid: vipInlichting?.vergunningVerlenendeOverheid,
    beroepsprocedures: vipInlichting?.beroepsprocedures ?? [],
    verval: vipInlichting?.verval,
    intrekking: vipInlichting?.intrekking,
    externeDocumentatie: vipInlichting?.externeDocumentatie
  } as StedenbouwkundigeMeldingInput
}

function mapInputToInlichting (input: StedenbouwkundigeMeldingInput, inlichtingId: string): StedenbouwkundigeMeldingInlichting {
  return {
    inlichtingId,
    inlichtingenIndicator: input.inlichtingenIndicator as unknown as InlichtingenIndicator,
    inlichtingType: VipInlichtingType.STEDENBOUWKUNDIGE_MELDING,
    version: 1,
    bronInformatie: {
      informatieverstrekker: 'Lokaal bestuur',
      laatsteWijzigingTijdstip: new Date()
    } as BronInformatie,
    kaarten: [],
    rubriek: InlichtingRubriek.VERGUNNINGEN,
    referentie: input.referentie,
    beschrijving: input.beschrijving,
    gemeentelijkDossiernummer: input.gemeentelijkDossiernummer,
    aardAanvraag: input.aardAanvraag,
    beslissingEersteAanleg: input.beslissingEersteAanleg,
    datumBeslissing: input.datumBeslissing,
    vergunningVerlenendeOverheid: input.vergunningVerlenendeOverheid,
    voorwaarden: input.voorwaarden,
    beroepsprocedures: input.beroepsprocedures,
    verval: input.verval,
    intrekking: input.intrekking,
    externeDocumentatie: input.externeDocumentatie
  } as StedenbouwkundigeMeldingInlichting
}

export default defineComponent({
  name: 'StedenbouwkundigeMelding',
  components: { VervalIntrekking, ContentCollection, Beroepsprocedure: BeroepsprocedureComponent, VipInlichtingVanToepassing, VipInlichtingPreviewNew, VipInlichtingEdit },
  props: {
    inlichting: {
      type: Object as PropType<Inlichting>,
      required: false
    },
    dossierId: {
      type: String,
      required: true
    },
    dossierStatus: {
      type: Number as PropType<DossierStatus>,
      required: false
    },
    canDelete: Boolean
  },
  setup (props, { emit }) {
    const {
      refs,
      inlichtingKey,
      vipInlichting,
      inlichtingInput,
      canEdit,
      validationErrors,
      accordion,
      saving,
      addedBijlagen,
      isNietGekend,
      bijlageUploaded,
      toggleIndicator,
      inlichtingToggled,
      cancelEdit,
      saveInlichting,
      validationFailed,
      downloadUrl,
      bijlagenSaved,
      bijlageDeleted
    } = useEditableInlichting(
      props.inlichting ?? ({ inlichtingType: VipInlichtingType.STEDENBOUWKUNDIGE_MELDING } as unknown as StedenbouwkundigeMeldingInlichting),
      mapInlichtingToInput,
      mapInputToInlichting)

    const vervalIntrekkingInput = ref(mapVervalIntrekkingInput(vipInlichting.value.verval, vipInlichting.value.intrekking) as VervalIntrekkingInput)

    const inlichtingType = TypeInlichting.StedenbouwkundigeMelding
    const inlichtingTitle = computed(() => inlichtingen.find(inl => inl.inlichtingType === inlichtingType).label)
    const beroepAangetekend = computed({
      get () {
        return inlichtingInput.value.inlichtingenIndicator === InlichtingInputInlichtingenIndicator.JA &&
            (inlichtingInput.value.beroepsprocedures ?? []).length > 0
      },
      set (value) {
        const input = structuredClone(inlichtingInput.value)
        if (value) {
          input.beroepsprocedures = (props.inlichting as StedenbouwkundigeMeldingInlichting).beroepsprocedures ?? []
          if (input.beroepsprocedures.length === 0) {
            addBeroep()
          }
        } else {
          input.beroepsprocedures = []
        }
        inlichtingInput.value = input
      },
    })

    const { statusOptions, intrekkingPartijOptions, statusToekomstOptions } = useVervalIntrekkingLabels()

    const optionsBeslissing = new Map([
      ['AKTENAME', 'Aktename'],
      ['GEEN_AKTENAME', 'Geen aktename'],
      ['AKTENAME_ONDER_VOORWAARDEN', 'Aktename onder voorwaarden'],
    ])

    const optionsAard = new Map([
      ['GROND_OPSLAG', 'Gronden gebruiken, aanleggen of inrichten voor de opslag van gebruikte of afgedankte voertuigen, van allerhande materialen, materieel of afval'],
      ['GROND_PARKEREN', 'Gronden gebruiken, aanleggen of inrichten voor parkeren van voertuigen, wagens of aanhangwagens'],
      ['GROND_PLAATSEN_PUBLICITEIT', 'Gronden gebruiken, aanleggen of inrichten voor plaatsen van 1 of meer verplaatsbare inrichtingen of rollend materieel die hoofdzakelijk publicitaire doeleinden worden gebruikt'],
      ['NIET_GEKEND', 'Niet gekend'],
      ['NIEUW_BIJGEBOUW', 'Nieuwbouw bijgebouw'],
      ['NIEUW_EENGEZINSWONING', 'Nieuwbouw eengezinswoning'],
      ['NIEUW_HANDEL', 'Nieuwbouw handel, horeca, diensten (bvb banken, winkels, winkelcentra, …)'],
      ['NIEUW_INDUSTRIE', 'Nieuwbouw industrie, ambacht'],
      ['NIEUW_INFRASTRUCTUUR', 'Nieuwbouw infrastructuren'],
      ['NIEUW_KANTOREN', 'Nieuwbouw kantoren'],
      ['NIEUW_LAND_TUINBOUW', 'Nieuwbouw land- en tuinbouw'],
      ['NIEUW_MEERGEZINSWONING', 'Nieuwbouw meergezinswoning'],
      ['NIEUW_OPENBAAR_NUT', 'Nieuwbouw gemeenschapsvoorziening of openbaar nut (scholen, religieuze gebouwen, ziekenhuizen, rusthuizen, …)'],
      ['NIEUW_TOERISME', 'Nieuwbouw toerisme en recreatie'],
      ['ONTBOSSEN', 'Ontbossen'],
      ['PUBLICITEITSINRICHTINGEN', 'Publiciteitsinrichtingen'],
      ['RELIEFWIJZIGING', 'Reliëfwijziging'],
      ['SLOPEN_BIJGEBOUW', 'Slopen bijgebouw", "Slopen bijgebouw'],
      ['SLOPEN_EENGEZINSWONING', 'Slopen eengezinswoning", "Slopen eengezinswoning'],
      ['SLOPEN_HANDEL', 'Slopen handel, horeca'],
      ['SLOPEN_INDUSTRIE', 'Slopen industrie, ambacht'],
      ['SLOPEN_INFRASTRUCTUUR', 'Slopen infrastructuren'],
      ['SLOPEN_KANTOREN', 'Slopen kantoren", "Slopen kantoren'],
      ['SLOPEN_LAND_TUINBOUW', 'Slopen land- en tuinbouw'],
      ['SLOPEN_MEERGEZINSWONING', 'Slopen meergezinswoning", "Slopen meergezinswoning'],
      ['SLOPEN_OPENBAAR_NUT', 'Slopen gemeenschapsvoorziening of openbaar nut'],
      ['SLOPEN_TOERISME', 'Slopen toerisme en recreatie'],
      ['VELLEN_BOMEN', 'Vellen hoogstammige bomen'],
      ['VERBOUW_BIJGEBOUW', 'Verbouwen van/tot of uitbreiden van bijgebouw'],
      ['VERBOUW_EENGEZINSWONING', 'Verbouwen van/tot of uitbreiden van eengezinswoning'],
      ['VERBOUW_HANDEL', 'Verbouwen van/tot of uitbreiden van handel, horeca, diensten'],
      ['VERBOUW_INDUSTRIE', 'Verbouwen van/tot of uitbreiden van industrie, ambacht'],
      ['VERBOUW_INFRASTRUCTUUR', 'Verbouwen van/tot of uitbreiden van infrastructuren'],
      ['VERBOUW_KANTOREN', 'Verbouwen van/tot of uitbreiden van kantoren'],
      ['VERBOUW_LAND_TUINBOUW', 'Verbouwen van/tot of uitbreiden van land - en tuinbouw'],
      ['VERBOUW_MEERGEZINSWONING', 'Verbouwen van/tot of uitbreiden van meergezinswoning'],
      ['VERBOUW_OPENBAAR_NUT', 'Verbouwen van/tot of uitbreiden van gemeenschapsvoorziening of openbaar nut'],
      ['VERBOUW_TOERISME', 'Verbouwen van/tot of uitbreiden van toerisme en recreatie'],
      ['VERBOUWEN_ZONDER_FUNCTIEWIJZIGING', 'Verbouwen zonder functiewijziging en met wijziging van het aantal woongelegenheden'],
      ['WIJZIG_AANTAL_WOONEENHEDEN', 'Wijzigen aantal woongelegenheden bestemd voor de huisvesting van een gezin of een alleenstaande']
    ])

    const optionsInstantie = new Map([
      ['', ''],
      ['COLLEGE_BURGEMEESTER_SCHEPENEN', 'College van Burgemeester en Schepenen'],
      ['PROVINCIE', 'Provincie'],
      ['VLAAMS_GEWEST', 'Vlaams Gewest']
    ])

    const { beroepsinstantieOptions, beslissingOptions: beroepBeslissingOptions } = useBeroepLabels()

    const getVervalPreview = (): string => {
      let previewText = ''
      const vervalDate = new Date(vipInlichting.value.verval.datum ?? new Date())
      const today = new Date()
      // Set the time part to 00:00:00 to ignore the time
      vervalDate.setHours(0, 0, 0, 0)
      today.setHours(0, 0, 0, 0)
      if (vervalDate > today) {
        previewText = statusToekomstOptions.get(vipInlichting.value.verval.status)
      } else {
        previewText = statusOptions.get(vipInlichting.value.verval.status)
      }
      if (vipInlichting.value.verval.datum) {
        previewText += ` op ${formatDate(vipInlichting.value.verval.datum)}`
      }
      return previewText
    }

    const validateInput = (): Map<string, string> => {
      const errors = new Map<string, string>()
      if (!inlichtingInput.value.referentie) {
        errors.set('referentie', 'Dossiernummer is verplicht')
      }
      if (inlichtingInput.value.beslissingEersteAanleg == null) {
        errors.set('beslissing', 'Beslissing is verplicht')
      }
      if (inlichtingInput.value.datumBeslissing == null) {
        errors.set('datumBeslissing', 'Beslist op is verplicht')
      }
      inlichtingInput.value.beroepsprocedures?.forEach((procedure, index) => {
        if (procedure.beroepsinstantie == null) {
          errors.set(`beroep-${index}-instantie`, 'Instantie is verplicht')
        }
        if (procedure.beslissing == null) {
          errors.set(`beroep-${index}-beslissing`, 'Beslissing is verplicht')
        }
      })
      if (inlichtingInput.value.intrekking != null) {
        if (inlichtingInput.value.intrekking.partij == null) {
          errors.set('intrekking-partij', 'Ingetrokken door is verplicht')
        }
      }
      return errors
    }

    const saved = (inlichtingId: string) => {
      saveInlichting(inlichtingId)
      emit('saved', vipInlichting.value)
      inlichtingToggled(true)
    }

    const removed = () => {
      emit('removed', vipInlichting.value.inlichtingId)
      EventBus.$emit('close-modal', 'vl-modal-backdrop')
    }

    const cancelled = () => {
      cancelEdit()
      emit('cancelled')
    }

    const addBeroep = () => {
      const input = structuredClone(inlichtingInput.value)
      input.beroepsprocedures.push({ beroepsinstantie: BeroepsprocedureInstantie.RAAD_VOOR_VERGUNNINGSBETWISTINGEN } as BeroepsprocedureRaadVoorVergunningsbetwistingen)
      inlichtingInput.value = input
    }

    const removeBeroep = (index: number) => {
      const input = structuredClone(inlichtingInput.value)
      input.beroepsprocedures.splice(index, 1)
      inlichtingInput.value = input
    }

    const updateBeroep = (procedure: Beroepsprocedure, procedureIndex: number) => {
      const input = structuredClone(inlichtingInput.value)
      input.beroepsprocedures[procedureIndex] = procedure
      inlichtingInput.value = input
    }

    const handleVervalIntrekking = (vervalIntrekkingInput: VervalIntrekkingInput) => {
      const input = structuredClone(inlichtingInput.value)
      if (vervalIntrekkingInput.status === StedenbouwkundigVervalStatus.INGETROKKEN) {
        input.intrekking = { partij: vervalIntrekkingInput.partij, datum: vervalIntrekkingInput.datum, motivering: vervalIntrekkingInput.motivering } as VipApiIntrekking
        input.verval = null
      } else {
        input.verval = { status: vervalIntrekkingInput.status, datum: vervalIntrekkingInput.datum, motivering: vervalIntrekkingInput.motivering } as StedenbouwkundigVerval
        input.intrekking = null
      }
      inlichtingInput.value = input
    }

    return {
      vipInlichting,
      inlichtingInput,
      vervalIntrekkingInput,
      beroepAangetekend,
      validationErrors,
      saving,
      accordion,
      refs,
      inlichtingKey,
      canEdit,
      addedBijlagen,
      inlichtingType,
      inlichtingTitle,
      optionsBeslissing,
      optionsAard,
      optionsInstantie,
      intrekkingPartijOptions,
      isNietGekend,
      beroepsinstantieOptions,
      beroepBeslissingOptions,
      bijlageUploaded,
      inlichtingToggled,
      toggleIndicator,
      validateInput,
      validationFailed,
      saved,
      removed,
      cancelled,
      downloadUrl,
      bijlagenSaved,
      bijlageDeleted,
      addBeroep,
      removeBeroep,
      updateBeroep,
      handleVervalIntrekking,
      getVervalPreview,
      formatNumber,
      formatDate
    }
  }
})
