import { defineComponent } from 'vue'

export default defineComponent({
  name: 'content-collection-item',
  props: {
    modRemovable: Boolean
  },
  setup (_, { emit }) {
    const remove = () => {
      emit('remove')
    }
    return { remove }
  }
})
